import { IProcessDetailsItem, IProcessStepInstanceFailureItem, IProcessStepInstanceSystemItem, IProcessStepItem, IProcessStepInstanceFileReference, IBossMessageFile } from './processDetailsModel';
import { ApiServiceBase } from "../../common/ApiServiceBase";

export class ProcessDetailsApi extends ApiServiceBase {

    public getItem(id: string): Promise<IProcessDetailsItem> {

        return new Promise((resolve) => {

            this.get('/process-instances/' + id)
            .then(responseData => {
                const item = this.createEmptyItem();
                item.id = responseData.processInstance.id;
                item.name = responseData.processInstance.name;
                item.customer = responseData.processInstance.customer;
                item.processState = responseData.processInstance.processState;
                item.correlationId = responseData.processInstance.correlationId;
                item.stepNodes = responseData.stepNodes;
                item.originalCorrelationId = '';
                item.uniqueId = '';

                const originalCorrelationIds = [];
                const uniqueIds = [];

                for (const stepInstance of responseData.processInstance.stepInstances) {
                    const failures = new Array<IProcessStepInstanceFailureItem>();
                    if(stepInstance.stepInstanceFailures){
                        for(const stepInstanceFailureItem of stepInstance.stepInstanceFailures){
                            if(this.IsJsonString(stepInstanceFailureItem.errorMessage)){
                                const failure: IProcessStepInstanceFailureItem = JSON.parse(stepInstanceFailureItem.errorMessage)
                                if(failure.Body){
                                    if(this.IsJsonString(failure.Body)){
                                        const body = JSON.parse(failure.Body);
                                        const files: IBossMessageFile[] = [];
                                        if(body.Files && Array.isArray(body.Files)){
                                            body.Files.forEach((file: any) =>{
                                                const bossMessageFile: IBossMessageFile = {
                                                    MessageType: file['Message-Type'],
                                                    System: file.System,
                                                    Url: file.Url,
                                                    Name: file.Url.replace(/^.*[\\\/]/, ''),
                                                    UniqueId: file['Unique-ID'],
                                                    ContentType: file['Content-Type'],
                                                    CorrelationId: file['Correlation-ID']
                                                }
                                                files.push(bossMessageFile);  
                                            })
                                        }
                                        failure.BossMessage = {
                                            Customer: body.Customer,
                                            CorrelationId: body['Correlation-ID'],
                                            MessageType: body['Message-Type'],
                                            System: body.System,
                                            Files: files
                                        }
                                    }
                                }
                                failures.push(failure)
                            }
                            else{
                                const failure: IProcessStepInstanceFailureItem = {
                                    ActionName: "Internal Brain Boss server exception",
                                    Body: stepInstanceFailureItem.errorMessage,
                                    HttpStatusCode: "500",
                                    StartTime: stepInstanceFailureItem.errorMessage.DateTimeUtc,
                                    Workflow: "InputWorker"
                                };
                                failures.push(failure)
                            }
                        }
                    }
                    
                    const step: IProcessStepItem = {
                        id: stepInstance.id,
                        messageType: stepInstance.messageType,
                        sessionId: stepInstance.sessionId,
                        stepState: stepInstance.stepState,
                        sessionFinalizer: stepInstance.sessionFinalizer + " ",
                        stepInstanceFailures: failures,
                        stepInstanceSystems: new Array<IProcessStepInstanceSystemItem>(),
                        fileReferences: new Array<IProcessStepInstanceFileReference>()
                    };

                    for (const stepInstanceSystemItem of stepInstance.stepSystemInstances) {
                        step.stepInstanceSystems.push({
                            id: stepInstanceSystemItem.id,
                            system: stepInstanceSystemItem.system,
                            action: stepInstanceSystemItem.action,
                            actionDescription: stepInstanceSystemItem.actionDescription,
                            runs: stepInstanceSystemItem.runs
                        });
                    }

                    for(const stepInstanceFileReference of stepInstance.fileReferences){
                        step.fileReferences.push({
                            correlationId: stepInstanceFileReference.correlationId,
                            messageType: stepInstanceFileReference.messageType,
                            name: stepInstanceFileReference.name,
                            uniqueId: stepInstanceFileReference.uniqueId,
                            url: stepInstanceFileReference.url,
                            system: stepInstanceFileReference.system
                        });

                        if(originalCorrelationIds.indexOf(stepInstanceFileReference.correlationId) === -1) {
                            originalCorrelationIds.push(stepInstanceFileReference.correlationId);
                        }

                        if(uniqueIds.indexOf(stepInstanceFileReference.uniqueId) === -1) {
                            uniqueIds.push(stepInstanceFileReference.uniqueId);
                        }
                    }
                    item.steps.push(step);
                }

                item.originalCorrelationId = originalCorrelationIds.join();
                item.uniqueId = uniqueIds.join(', ');
                
                resolve(item);
            });
        });
    }

    public IsJsonString(str: string) {
        if(str === null){
            return false;
        }
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public resendProcess(id: string): Promise<string> {
        return new Promise((resolve) => {
            this.post('/step-instances/' + id + "/step-system-instances/" + id + "/resend")
            .then(response => {
                resolve("");
            });          
        });
    }

    public resendStepInstance(id: string): Promise<string> {
        return new Promise((resolve) => {
            this.post('/step-instances/' + id + "/step-system-instances/" + id + "/resendStepInstance")
            .then(response => {
                resolve("");
            });          
        });
    }

    public downloadFile(url: string, id: string, name: string, system: string): Promise<void> {
        return new Promise((resolve) => {
            this.fetchRaw('/step-instances/' + id + '/file-reference?path=' + encodeURIComponent(url) + '&system=' + system)
            .then((response: Response) => {
                if (response.ok){

                    response.blob().then(blob =>
                    {
                        if (typeof window.navigator.msSaveBlob !== 'undefined') {
                            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                            window.navigator.msSaveBlob(blob, name);
                        } else {
                            const URL = window.URL || (window as any).webkitURL;
                            const downloadUrl = URL.createObjectURL(blob);
        
                            // use HTML5 a[download] attribute to specify filename
                            const a = document.createElement("a");
                            // safari doesn't support this yet
                            if (typeof a.download === 'undefined') {
                                window.location.href = downloadUrl;
                            } else {
                                a.href = downloadUrl;
                                a.download = name;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                            setTimeout(() => { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                        }
                        resolve();
                    });
                } else {
                    alert('Error downloading file. Status:' + response.status);
                    resolve();
                }
            });          
        });
    }

    public downloadAllFiles(processInstanceId: string): Promise<void> {
        return new Promise((resolve) => {
            this.postRawData('/process-instances/files-references', new Array(processInstanceId))
            .then((response: Response) => {
                if (response.ok){
                    
                    response.blob().then(blob =>
                    {
                        if (typeof window.navigator.msSaveBlob !== 'undefined') {
                            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                            window.navigator.msSaveBlob(blob, 'payloads.zip');
                        } else {
                            const URL = window.URL || (window as any).webkitURL;
                            const downloadUrl = URL.createObjectURL(blob);
        
                            // use HTML5 a[download] attribute to specify filename
                            const a = document.createElement("a");
                            // safari doesn't support this yet
                            if (typeof a.download === 'undefined') {
                                window.location.href = downloadUrl;
                            } else {
                                a.href = downloadUrl;
                                a.download = 'payloads.zip';
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                            setTimeout(() => { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                        }
                        resolve();
                    });
                } else {
                    alert('Error downloading file. Status:' + response.status);
                    resolve();
                }
            });          
        });
    }

    private createEmptyItem() : IProcessDetailsItem {
        return {
            id: '',
            name: '',
            correlationId: '',
            customer: '',
            processState: '',
            originalCorrelationId: '',
            uniqueId: '',
            steps: [],
            stepNodes: [{
                name: '.',
                attributes: {
                },
                children: []
            }]
         };
    }
}