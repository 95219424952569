import {IProcessChangeHistory, IProcessDefinition} from "./models";
import {ApiServiceBase} from "src/common/ApiServiceBase";

const PROCESSES_URL = '/processes';

export default class ProcessesApi extends ApiServiceBase {

    public getProcessDefinition(processName: string): Promise<IProcessDefinition> {
        return Promise.all([
            this.get(PROCESSES_URL + '/' + processName)
        ]).then(items => items[0]);
    }

    public getProcessChangeHistory(processName: string, customer: string): Promise<IProcessChangeHistory[]> {
        return Promise.all([
            this.get(PROCESSES_URL + '/' + processName + "/" + customer + '/changehistory')
        ])
            .then(items => new Promise<IProcessChangeHistory[]>(resolve => {
                if(items == null) {
                    return [];
                }
                return resolve(items[0]);
            }));
    }

}