import {ICustomerGroupConfigurationState} from "./State";
import {Action} from "redux";
import * as actionTypes from './ActionTypes';

export interface IGroupConfigReducerAction extends Action<string> {
    newState: Partial<ICustomerGroupConfigurationState>;
}

const defaultState: ICustomerGroupConfigurationState = {
    isLoading: true,
    selectedCustomerGroup: {
        id: '',
        name: '',
        beCodes: [''],
        processIds: null,
        steps: null,
        type: ''
    },
    customerGroupDifferences: [],
    customers: [],
    selectedProcessDefinition: null,
    capabilities: null,
    customerGroups: null
}

export default function CustomerGroupReducer(
    state: ICustomerGroupConfigurationState = defaultState,
    action: IGroupConfigReducerAction): ICustomerGroupConfigurationState {
    switch (action.type) {
        case actionTypes.GROUP_CONFIGURATION_APPEND_PROCESS_DIFFERENCES:
            const differences = [
                ...state.customerGroupDifferences,
                ...action.newState.customerGroupDifferences!
            ];
            return {...state, ...action.newState, customerGroupDifferences: differences}
        case actionTypes.GROUP_CONFIGURATION_REPLACE_PROCESS_DIFFERENCE:
            return {
                ...state,
                ...action.newState,
                customerGroupDifferences: state.customerGroupDifferences.map(item =>
                    action.newState.customerGroupDifferences?.find(i => i.beCode === item.beCode) ?? item
                )
            }
        default:
            return {...state, ...action.newState}
    }
}