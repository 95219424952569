import * as React from "react";
import {ChangeEvent} from "react";
import {ICustomerProcessDefinition} from "../models/ICustomerProcessDefinition";


interface IProcessDefinitionTableProps {
    processDefinition: ICustomerProcessDefinition | null;
    onStatusChange: (newStatus: boolean) => void;
    readonly: boolean;
    processOwnerName: string;
}

export default class ProcessDefinitionTable extends React.Component<IProcessDefinitionTableProps> {
    private static makeRow(label: string, item: string) {
        return (
            <tr>
                <td><b>{label}: </b></td>
                <td>{item}</td>
            </tr>
        )
    }

    constructor(props: IProcessDefinitionTableProps) {
        super(props);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    public render() {
        if (this.props.processDefinition == null) {
            return <div/>
        }
        return (
            <table style={{width: "50%"}}>
                <tbody>
                {ProcessDefinitionTable.makeRow('Customer', this.props.processOwnerName)}
                {ProcessDefinitionTable.makeRow('Capability', this.props.processDefinition.capabilityId)}
                {ProcessDefinitionTable.makeRow('Process Name', this.props.processDefinition.name)}
                {this.getStatusRow()}
                </tbody>
            </table>
        )
    }

    private getStatusRow() {
        return (
            <tr>
                <td>
                    <b>Status:</b>
                </td>
                <td>
                    <div className="checkbox">
                        <label>
                            <input type="checkbox"
                                   name={this.props.processDefinition!.name}
                                   checked={this.props.processDefinition!.enabled}
                                   disabled={this.props.readonly}
                                   onChange={this.onCheckboxChange}
                            />
                            &nbsp;
                            <span>{this.props.processDefinition!.enabled ? "Enabled" : "Disabled"}</span>
                        </label>
                    </div>
                </td>
            </tr>
        )
    }

    private onCheckboxChange(e: ChangeEvent<HTMLInputElement>): void {
        if (this.props.readonly) {
            return;
        }
        this.props.onStatusChange(e.currentTarget.checked)
    }
}