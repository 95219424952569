import * as React from "react";
import { conditionComponent as conditionalComponent } from '../settings/conditionComponent';

export enum ButtonType {
    Blue = "button button-blue" ,
    Red = "button button-red",
    Transparent = "button button-transparent",
    Contextual = "button button-context",
    Close = "close",
}

interface IButtonProps{
    type?: string;
    onClick?: () => void;
    buttonType: ButtonType;
    isDisabled?: boolean;
    children: React.ReactChild | React.ReactChild[] | string;
    style?: any;
    className?: string;
}

export const Button: React.SFC<IButtonProps> = (props: IButtonProps) => {
    const className = `${props.buttonType} ${props.className ? props.className : ''}`;
    return (
        <button
            style={props.style}
            type="button" 
            disabled={props.isDisabled} 
            className={className}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

export const CondtionalButton = conditionalComponent(Button);
