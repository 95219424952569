import * as React from "react";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {IRootReducer} from "../../store/rootReducer";
import * as configurationActions from "../store/CustomerGroup/Actions";
import {DropDownFormGroup, IDropDownItem} from "../../common";
import {match, withRouter} from "react-router-dom";

interface IMatchParams {
    name: string;
}

interface ICustomerGroupSelectorProps {
    customerGroups: IDropDownItem[];
    actions: typeof configurationActions;
    match: match<IMatchParams>;
}

interface ICustomerGroupSelectorState {
    selected: string;
}

class CustomerGroupSelector extends React.Component<ICustomerGroupSelectorProps, ICustomerGroupSelectorState> {
    public constructor(props: ICustomerGroupSelectorProps) {
        super(props);
        this.state = {
            selected: ""
        }
        this.onCustomerGroupSelect = this.onCustomerGroupSelect.bind(this);
    }

    public render() {
        let content;
        if (this.props.customerGroups) {
            content = <DropDownFormGroup
                name="customer-group"
                labelText="Customer Group"
                formGroupClassName="col-25"
                elements={this.props.customerGroups}
                onChange={this.onCustomerGroupSelect}
                value={this.state.selected}
            />
        } else {
            content = <div/>
        }

        return (
            <div className="grid-wrapper">
                {content}
            </div>
        )
    }

    public componentDidMount() {
        this.props.actions.loadCustomerGroups();
        const customerGroupName =this.props.match.params.name;
        if (!!customerGroupName) {
            this.setState({selected: customerGroupName}, () => {
                    this.props.actions.loadCustomerGroup(customerGroupName);
                    this.props.actions.loadCustomerGroupDifferences(customerGroupName);
                }
            )
        }
    }

    private onCustomerGroupSelect(e: any) {
        const key = e.currentTarget.value;
        const customerGroup: IDropDownItem | undefined = this.props.customerGroups.find(group => group.key === key);
        if (!!customerGroup) {
            const customerGroupName: string = customerGroup.key;
            this.setState({selected: customerGroupName}, () => {
                    this.props.actions.loadCustomerGroup(customerGroupName);
                    this.props.actions.loadCustomerGroupDifferences(customerGroupName);
                }
            )
        }
    }
}

const mapStateToProps = (state: IRootReducer) => {
    return {
        customerGroups: state.groupConfigurationView.customerGroups
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(configurationActions, dispatch)
    }
}
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CustomerGroupSelector);
