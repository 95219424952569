import * as React from 'react';
import BackButton from './BackButton';

export interface IPageHeaderProps {
    title: string;
}

export const PageHeader = ((props: IPageHeaderProps) => {

    return (
        <div>
            <div className="header-group profile-template">
            <ul className="page-title-group">
                <li>
                    <BackButton />
                </li>
                <li>
                    <h1>{props.title}</h1>
                </li>
            </ul>
            </div>
        </div>
    );
});