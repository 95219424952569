import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as H from 'history';

// variable which will point to react-router history
let globalHistory : H.History<any>;

// component which we will mount on top of the app
class GlobalHistoryComponent extends React.Component<RouteComponentProps> {
  public componentWillMount() {
    const { history } = this.props;
    globalHistory = history; 
  }

  public componentWillReceiveProps(nextProps:any) {
    globalHistory = nextProps.history;
  }

  public render(){
    return null;
  }
}

export const GlobalHistory = withRouter(GlobalHistoryComponent);

// export react-router history
export default function getHistory() {    
  return globalHistory;
}