export const OPERATIONAL_LOAD_IN_PROGRESS = 'OPERATIONAL_LOAD_IN_PROGRESS';
export const OPERATIONAL_LOAD_ERROR = 'OPERATIONAL_LOAD_ERROR';
export const OPERATIONAL_LOAD_PROCESSES_SUCCESS = 'OPERATIONAL_LOAD_PROCESSES_SUCCESS';
export const OPERATIONAL_LOAD_PROCESSES_NEXT_PAGE_SUCCESS = 'OPERATIONAL_LOAD_PROCESSES_NEXT_PAGE_SUCCESS';
export const OPERATIONAL_LOAD_FILTER_DICTIONARIES = 'OPERATIONAL_LOAD_FILTER_DICTIONARIES';
export const OPERATIONAL_CLEAR_RESULTS = 'OPERATIONAL_CLEAR_RESULTS';
export const OPERATIONAL_CHANGE_FILTER_TO_PROCESSES = 'OPERATIONAL_CHANGE_FILTER_TO_PROCESSES';
export const OPERATIONAL_CHANGE_PROCESSES_MORE_FILTERS = 'OPERATIONAL_CHANGE_PROCESSES_MORE_FILTERS';
export const OPERATIONAL_CHANGE_FILTERS = 'OPERATIONAL_CHANGE_FILTERS';
export const OPERATIONAL_DOWNLOAD_ALL_FILES_START = "OPERATIONAL_DOWNLOAD_ALL_FILES_START";
export const OPERATIONAL_DOWNLOAD_ALL_FILES_END = "OPERATIONAL_DOWNLOAD_ALL_FILES_END";
export const OPERATIONAL_DOWNLOAD_ALL_FILES_ERROR = "OPERATIONAL_DOWNLOAD_ALL_FILES_ERROR";

