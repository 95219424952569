/* tslint:disable:jsx-no-lambda */
/* This rule is disabled because I do not know any other option to add a class depending on the current route  */
import * as React from 'react';
import { Route, Link } from 'react-router-dom';

export const NavItem =  (props: any) => {
    return (
        <Route path={props.to} exact={props.exact} children={({match}) => (
            <li>
                <Link onClick={props.onClick} to={props.to}>{props.children}</Link>
            </li>
        )}/>
    );
}
