import * as actionTypes from './processDetailsActionTypes';
import { IProcessDetailsView } from './processDetailsModel';
import { ProcessDetailsApi } from './ProcessDetailsApi';

const api = new ProcessDetailsApi();

export const loadItemSuccess = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_LOAD_ITEM_SUCCESS, mainView };
}

export const loadItemError = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_LOAD_ITEM_ERROR, mainView };
}

export const loadItemInProgress = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_LOAD_ITEM_IN_PROGRESS, mainView };
}

export const showResendPanelAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_SHOW_RESENDPANEL, mainView };
}

export const hideResendPanelAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_HIDE_RESENDPANEL, mainView };
}

export const resendStartAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_RESEND_START, mainView };
}

export const resendCancelAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_RESEND_CANCEL, mainView };
}

export const resendProcessingAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_RESEND_PROCESSING, mainView };
}

export const resendEndAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_RESEND_END, mainView };
}

export const downloadStartFileAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_DOWNLOAD_FILE_START, mainView };
}

export const downloadEndFileAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_DOWNLOAD_FILE_END, mainView };
}

export const downloadErrorFileAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_DOWNLOAD_FILE_ERROR, mainView };
}

export const downloadStartAllFilesAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_DOWNLOAD_ALL_FILES_START, mainView };
}

export const downloadEndAllFilesAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_DOWNLOAD_ALL_FILES_END, mainView };
}

export const downloadErrorAllFilesAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_DOWNLOAD_ALL_FILES_ERROR, mainView };
}

export const resendProcessStartAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_RESEND_PROCESS_START, mainView };
}

export const resendProcessEndAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_RESEND_PROCESS_END, mainView };
}

export const resendProcessErrorAction = (mainView: IProcessDetailsView) => {
    return { type: actionTypes.PROCESSDETAILS_RESEND_PROCESS_ERROR, mainView };
}

export const loadItem = (id:string, viewState: IProcessDetailsView) => {
    return (dispatch: any) => {
        dispatch(loadItemInProgress(
            { 
                loading: true, 
                showResendPanel: false, 
                showResendUserConfirmationDialog: false,
                showResendConfirmation: false,
                item: {
                    id: '',
                    name: '',
                    correlationId: '',
                    customer: '',
                    processState: '',
                    steps: [],
                    originalCorrelationId: '',
                    uniqueId: '',
                    stepNodes: [{
                        name: '.',
                        attributes: {
                        },
                        children: []
                    }]
                }, 
                errorMessage: '' 
            }));
        api.getItem(id)
            .then(item => {
                dispatch(loadItemSuccess(
                    { 
                        loading: false, 
                        showResendPanel: false, 
                        showResendUserConfirmationDialog: false,
                        showResendConfirmation: false,
                        item, 
                        errorMessage: '' 
                    }));
            })
            .catch(error => {
                console.log(error);
                dispatch(loadItemError( 
                    { 
                        loading: false, 
                        showResendPanel: false, 
                        showResendUserConfirmationDialog: false,
                        showResendConfirmation: false,
                        item: {
                            id: '',
                            name: '',
                            correlationId: '',
                            customer: '',
                            processState: '',
                            originalCorrelationId: '',
                            uniqueId: '',
                            steps: [],
                            stepNodes: [{
                                name: '.',
                                attributes: {
                                },
                                children: []
                            }]
                        }, 
                        errorMessage: 'An error has occurred' 
                    }));
            });
    }
}

export const showResendPanel = (viewState: IProcessDetailsView) => {
    return (dispatch: any) => {
        dispatch(showResendPanelAction(
            { 
                loading: false, 
                showResendPanel: true, 
                showResendUserConfirmationDialog: false,
                showResendConfirmation: false, 
                item: {
                    id: '',
                    name: '',
                    correlationId: '',
                    customer: '',
                    processState: '',
                    originalCorrelationId: '',
                    uniqueId: '',
                    steps: [],
                    stepNodes: [{
                        name: '.',
                        attributes: {
                        },
                        children: []
                    }]
                }, 
                errorMessage: '' 
            }));
    }
}

export const hideResendPanel = (viewState: IProcessDetailsView) => {
    return (dispatch: any) => {
        dispatch(hideResendPanelAction(
            { 
                loading: false, 
                showResendPanel: false, 
                showResendUserConfirmationDialog: false,
                showResendConfirmation: false,
                item: {
                    id: '',
                    name: '',
                    correlationId: '',
                    customer: '',
                    processState: '',
                    originalCorrelationId: '',
                    uniqueId: '',
                    steps: [],
                    stepNodes: [{
                        name: '.',
                        attributes: {
                        },
                        children: []
                    }]
                },
                errorMessage: '' 
            }));
    }
}

export const resendStart = (viewState: IProcessDetailsView) => {
    return (dispatch: any) => {
        dispatch(resendStartAction(
            { 
                loading: false, 
                showResendPanel: false, 
                showResendUserConfirmationDialog: true,
                showResendConfirmation: false,
                item: {
                    id: '',
                    name: '',
                    correlationId: '',
                    originalCorrelationId: '',
                    uniqueId: '',
                    customer: '',
                    processState: '',
                    steps: [],
                    stepNodes: [{
                        name: '.',
                        attributes: {
                        },
                        children: []
                    }]
                },  
                errorMessage: '' 
            }));
    }
}

export const resendCancel = (viewState: IProcessDetailsView) => {
    return (dispatch: any) => {
        dispatch(resendCancelAction(
            { 
                loading: false, 
                showResendPanel: false, 
                showResendUserConfirmationDialog: false,
                showResendConfirmation: false,
                item: {
                    id: '',
                    name: '',
                    correlationId: '',
                    originalCorrelationId: '',
                    uniqueId: '',
                    customer: '',
                    processState: '',
                    steps: [],
                    stepNodes: [{
                        name: '.',
                        attributes: {
                        },
                        children: []
                    }]
                }, 
                errorMessage: '' 
            }));
    }
}

export const resendEnd = (id: string, viewState: IProcessDetailsView) => {
    return (dispatch: any) => {

        dispatch(resendProcessingAction(
        { 
            loading: true, 
            showResendPanel: false, 
            showResendUserConfirmationDialog: false,
            showResendConfirmation: false,
            item: {
                id: '',
                name: '',
                correlationId: '',
                originalCorrelationId: '',
                uniqueId: '',
                customer: '',
                processState: '',
                steps: [],
                stepNodes: [{
                    name: '.',
                    attributes: {
                    },
                    children: []
                }]
            }, 
            errorMessage: '' 
        }));

        api.resendProcess(id)
            .then(() => {
                dispatch(resendEndAction(
                { 
                    loading: false, 
                    showResendPanel: false,
                    showResendUserConfirmationDialog: false,
                    showResendConfirmation: true,
                    item: {
                        id: '',
                        name: '',
                        correlationId: '',
                        customer: '',
                        originalCorrelationId: '',
                        uniqueId: '',
                        processState: '',
                        steps: [],
                        stepNodes: [{
                            name: '.',
                            attributes: {
                            },
                            children: []
                        }]
                    }, 
                    errorMessage: '' 
                }));
            })
            .catch(error => {
                dispatch(loadItemError( 
                { 
                    loading: false, 
                    showResendPanel: false, 
                    showResendUserConfirmationDialog: false,
                    showResendConfirmation: false, 
                    item: {
                        id: '',
                        name: '',
                        correlationId: '',
                        customer: '',
                        originalCorrelationId: '',
                        uniqueId: '',
                        processState: '',
                        steps: [],
                        stepNodes: [{
                            name: '.',
                            attributes: {
                            },
                            children: []
                        }]
                    }, 
                    errorMessage: 'An error has occurred' 
                }));
            });
    }
}

export const downloadFile = (url: string, id: string, name:string, system: string) => {
    return (dispatch: any) => {

        dispatch(downloadStartFileAction(
        { 
            loading: true, 
            showResendPanel: false, 
            showResendUserConfirmationDialog: false,
            showResendConfirmation: false,
            item: {
                id: '',
                name: '',
                correlationId: '',
                customer: '',
                processState: '',
                originalCorrelationId: '',
                uniqueId: '',
                steps: [],
                stepNodes: [{
                    name: '.',
                    attributes: {
                    },
                    children: []
                }]
            }, 
            errorMessage: '' 
        }));

        api.downloadFile(url, id, name, system)
            .then(() => {
                dispatch(downloadEndFileAction(
                { 
                    loading: false, 
                    showResendPanel: false,
                    showResendUserConfirmationDialog: false,
                    showResendConfirmation: true,
                    item: {
                        id: '',
                        name: '',
                        correlationId: '',
                        customer: '',
                        processState: '',
                        originalCorrelationId: '',
                        uniqueId: '',
                        steps: [],
                        stepNodes: [{
                            name: '.',
                            attributes: {
                            },
                            children: []
                        }]
                    }, 
                    errorMessage: '' 
                }));
            })
            .catch(error => {
                dispatch(downloadErrorFileAction( 
                { 
                    loading: false, 
                    showResendPanel: false, 
                    showResendUserConfirmationDialog: false,
                    showResendConfirmation: false, 
                    item: {
                        id: '',
                        name: '',
                        correlationId: '',
                        customer: '',
                        originalCorrelationId: '',
                        uniqueId: '',
                        processState: '',
                        steps: [],
                        stepNodes: [{
                            name: '.',
                            attributes: {
                            },
                            children: []
                        }]
                    }, 
                    errorMessage: 'An error has occurred while downloading file' 
                }));
            });
    }
}

export const downloadAllFiles = (id: string) => {
    return (dispatch: any) => {

        dispatch(downloadStartAllFilesAction(
        { 
            loading: true, 
            showResendPanel: false, 
            showResendUserConfirmationDialog: false,
            showResendConfirmation: false,
            item: {
                id: '',
                name: '',
                correlationId: '',
                customer: '',
                processState: '',
                originalCorrelationId: '',
                uniqueId: '',
                steps: [],
                stepNodes: [{
                    name: '.',
                    attributes: {
                    },
                    children: []
                }]
            }, 
            errorMessage: '' 
        }));

        api.downloadAllFiles(id)
            .then(() => {
                dispatch(downloadEndAllFilesAction(
                { 
                    loading: false, 
                    showResendPanel: false,
                    showResendUserConfirmationDialog: false,
                    showResendConfirmation: true,
                    item: {
                        id: '',
                        name: '',
                        correlationId: '',
                        customer: '',
                        processState: '',
                        originalCorrelationId: '',
                        uniqueId: '',
                        steps: [],
                        stepNodes: [{
                            name: '.',
                            attributes: {
                            },
                            children: []
                        }]
                    }, 
                    errorMessage: '' 
                }));
            })
            .catch(error => {
                dispatch(downloadErrorAllFilesAction( 
                { 
                    loading: false, 
                    showResendPanel: false, 
                    showResendUserConfirmationDialog: false,
                    showResendConfirmation: false, 
                    item: {
                        id: '',
                        name: '',
                        correlationId: '',
                        customer: '',
                        originalCorrelationId: '',
                        uniqueId: '',
                        processState: '',
                        steps: [],
                        stepNodes: [{
                            name: '.',
                            attributes: {
                            },
                            children: []
                        }]
                    }, 
                    errorMessage: 'An error has occurred while downloading file' 
                }));
            });
    }
}

export const resendProcess = (id: string) => {
    return (dispatch: any) => {

        dispatch(resendProcessStartAction(
        { 
            loading: true, 
            showResendPanel: false, 
            showResendUserConfirmationDialog: false,
            showResendConfirmation: false,
            item: {
                id: '',
                name: '',
                correlationId: '',
                customer: '',
                processState: '',
                originalCorrelationId: '',
                uniqueId: '',
                steps: [],
                stepNodes: [{
                    name: '.',
                    attributes: {
                    },
                    children: []
                }]
            }, 
            errorMessage: '' 
        }));

        api.resendProcess(id)
            .then(() => {
                dispatch(resendProcessEndAction(
                { 
                    loading: false, 
                    showResendPanel: false,
                    showResendUserConfirmationDialog: false,
                    showResendConfirmation: true,
                    item: {
                        id: '',
                        name: '',
                        correlationId: '',
                        originalCorrelationId: '',
                        uniqueId: '',
                        customer: '',
                        processState: '',
                        steps: [],
                        stepNodes: [{
                            name: '.',
                            attributes: {
                            },
                            children: []
                        }]
                    }, 
                    errorMessage: '' 
                }));
            })
            .catch(error => {
                dispatch(resendProcessErrorAction( 
                { 
                    loading: false, 
                    showResendPanel: false, 
                    showResendUserConfirmationDialog: false,
                    showResendConfirmation: false, 
                    item: {
                        id: '',
                        name: '',
                        correlationId: '',
                        customer: '',
                        originalCorrelationId: '',
                        uniqueId: '',
                        processState: '',
                        steps: [],
                        stepNodes: [{
                            name: '.',
                            attributes: {
                            },
                            children: []
                        }]
                    }, 
                    errorMessage: 'An error has occurred while resending message' 
                }));
            });
    }
}
export const resendStepInstance = (id: string) => {
    return (dispatch: any) => {

        dispatch(resendProcessStartAction(
        { 
            loading: true, 
            showResendPanel: false, 
            showResendUserConfirmationDialog: false,
            showResendConfirmation: false,
            item: {
                id: '',
                name: '',
                correlationId: '',
                customer: '',
                processState: '',
                originalCorrelationId: '',
                uniqueId: '',
                steps: [],
                stepNodes: [{
                    name: '.',
                    attributes: {
                    },
                    children: []
                }]
            }, 
            errorMessage: '' 
        }));

        api.resendStepInstance(id)
            .then(() => {
                dispatch(resendProcessEndAction(
                { 
                    loading: false, 
                    showResendPanel: false,
                    showResendUserConfirmationDialog: false,
                    showResendConfirmation: true,
                    item: {
                        id: '',
                        name: '',
                        correlationId: '',
                        originalCorrelationId: '',
                        uniqueId: '',
                        customer: '',
                        processState: '',
                        steps: [],
                        stepNodes: [{
                            name: '.',
                            attributes: {
                            },
                            children: []
                        }]
                    }, 
                    errorMessage: '' 
                }));
            })
            .catch(error => {
                dispatch(resendProcessErrorAction( 
                { 
                    loading: false, 
                    showResendPanel: false, 
                    showResendUserConfirmationDialog: false,
                    showResendConfirmation: false, 
                    item: {
                        id: '',
                        name: '',
                        correlationId: '',
                        customer: '',
                        originalCorrelationId: '',
                        uniqueId: '',
                        processState: '',
                        steps: [],
                        stepNodes: [{
                            name: '.',
                            attributes: {
                            },
                            children: []
                        }]
                    }, 
                    errorMessage: 'An error has occurred while resending message' 
                }));
            });
    }
}