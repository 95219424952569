import * as React from 'react';

interface IDetectScrollToBottomProps {
    onScrollToBottom?: () => void;
}

export class DetectScrollToBottom extends React.Component<IDetectScrollToBottomProps> {
    constructor(props: IDetectScrollToBottomProps) {
        super(props);

        this.onScroll = this.onScroll.bind(this);
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    public render() {
        return ( <div />);
    }

    private onScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;

        if (windowBottom >= docHeight - 50) {
            if (this.props.onScrollToBottom) {
                this.props.onScrollToBottom();
            }
        }
    }
}