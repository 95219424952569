import * as React from 'react';
import './styles/stripHeader.css';

export const StripHeader = (props: any) => {

    return(
        <div className="strip-header" onClick={props.onSelect}>
            {props.children}
        </div>
    );
}