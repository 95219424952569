import * as React from 'react';
import { withRouter } from 'react-router-dom'
import { PageHeader, IDropDownItem, LoadingAnimation, DetectScrollToBottom } from '../common/';
import { ProcessesFilterSection } from './ProcessesFilterSection';
import { IProcess, IOperationalFilter } from './OperationalApi';
import { ProcessesResult } from './ProcessesResult';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as operationalActions from './operationalActions';
import { IRootReducer } from '../store/rootReducer';
import * as moment from 'moment';

export interface IOperationalFilterState extends IOperationalFilter {
    processesList: IDropDownItem[];
    processStatuses: IDropDownItem[];
    capabilitiesList: IDropDownItem[];
    customers: IDropDownItem[];
    createDateFrom?: moment.Moment;
    createDateTo?: moment.Moment;
}

export interface IOperationalContainerState {
    intervalId: any;
    payloadsProcessesIds: string[];
}

export interface IOperationalContainer {
    isLoading: boolean;
    errorLoading: boolean;
    loadingErrorMessage: string;
    processes: IProcess[];
    filter: IOperationalFilterState;
    actions: any;
    history: any;
    location: any;
    nextPageToken: string;    
    processesMoreFilters?: boolean;
    stepsMoreFilters?: boolean;
}

export interface IOperationalHistoryParams {
    capability?: string;
    process?: string;
    processStatus?: string;
    createDateFrom?: string;
    createDateTo?: string;
}

class OperationalContainer extends React.Component<IOperationalContainer, IOperationalContainerState> {

    constructor(props: any) {
        super(props);

        this.state = {
            intervalId: null,
            payloadsProcessesIds: []
        };

        this.onFilterApply = this.onFilterApply.bind(this);
        this.onDownlaodAll = this.onDownlaodAll.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onFilterClear = this.onFilterClear.bind(this);        
        this.loadProcesses = this.loadProcesses.bind(this);
        this.onItemSelect = this.onItemSelect.bind(this);
        this.onProcessPayloadsSelect = this.onProcessPayloadsSelect.bind(this);
        this.onScrollToBottom = this.onScrollToBottom.bind(this);
        this.onProcessesMoreFiltersChanged = this.onProcessesMoreFiltersChanged.bind(this);
        this.onStepsMoreFilterChanged = this.onStepsMoreFilterChanged.bind(this);
    }

    public componentDidMount() {
        this.loadFilterDictionaries();
    }

    public componentWillUnmount() {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
    }

    public render() {        
        return (
            <div>
                <DetectScrollToBottom onScrollToBottom={this.onScrollToBottom} />
                <PageHeader title="Operational View" />
                { this.props.isLoading && <LoadingAnimation />}
                
                        <ProcessesFilterSection 
                                correlationId={this.props.filter.correlationId} 
                                uniqueId={this.props.filter.uniqueId}
                                carrierBookingRequestId={this.props.filter.carrierBookingRequestId}
                                capabilitiesList={this.props.filter.capabilitiesList}
                                processesList={this.props.filter.processesList}
                                processStatuses={this.props.filter.processStatuses}
                                customers={this.props.filter.customers}
                                onApply={this.onFilterApply} 
                                onChange={this.onFilterChange} 
                                onClear={this.onFilterClear}
                                onDownloadAll={this.onDownlaodAll}
                                selectedProcess={this.props.filter.process}
                                selectedCapability={this.props.filter.capability}
                                selectedCustomer={this.props.filter.customer}
                                selectedProcessStatus={this.props.filter.processStatus}
                                fromCurrentDate={this.props.filter.createDateFrom}
                                toCurrentDate={this.props.filter.createDateTo}
                                fromMaxDate={moment(undefined)}
                                toMaxDate={moment(undefined)}
                                moreFilters={this.props.processesMoreFilters}
                                onMoreFilters={this.onProcessesMoreFiltersChanged}
                                showDownloadAll={true}
                        />
                        <ProcessesResult
                            processes={this.props.processes} 
                            onProcessSelect={this.onItemSelect} 
                            onProcessPayloadsSelect={this.onProcessPayloadsSelect} />            
            </div>
        )
    }

    private updateFilters(newState: Partial<IOperationalFilterState>, callback?: () => void)
    {
        this.props.actions.changeFilterState({...this.props.filter, ...newState});

        if (callback) {
            callback();
        }

    }

    private onScrollToBottom() {
        if (this.props.nextPageToken && this.props.nextPageToken.length > 0 && !this.props.isLoading) {
            this.updateFilters({nextPageToken: this.props.nextPageToken}, () => {
                this.loadProcesses();
            });

        }
    }

    private onProcessesMoreFiltersChanged()
    {
        this.props.actions.changeMoreFiltersOnProcesses(this.props.processesMoreFilters);
    }

    private onStepsMoreFilterChanged()
    {
        this.props.actions.changeMoreFiltersOnSteps(this.props.stepsMoreFilters);
    }

    private onItemSelect(correlationId: string) {
        this.props.history.push('/operational/process/' + correlationId);
    }

    private onProcessPayloadsSelect(correlationId: string) {
        if(this.state.payloadsProcessesIds.indexOf(correlationId) < 0){
            this.state.payloadsProcessesIds.push(correlationId);
        }
        else{
            this.state.payloadsProcessesIds.splice(this.state.payloadsProcessesIds.indexOf(correlationId), 1);
        }
    }

    private onFilterApply() {
        this.updateFilters({nextPageToken: ''}, () => {
            this.loadProcesses();
        });
    }

    private onDownlaodAll() {
        if(this.state.payloadsProcessesIds.length > 0){
            this.props.actions.downloadAllFiles(this.state.payloadsProcessesIds);
        }
        else{
            alert("Please select some processes first from the list below.");
        }
    }

    private loadProcesses() {
        const filter: IOperationalFilter = Object.assign({}, this.props.filter);
        this.props.actions.loadProcesses(filter, this.props.processesMoreFilters);
    }

    private onFilterChange(e: any) {
        let propertyName = e.target.name;
        if (propertyName === 'quickFilter'){
            propertyName = 'correlationId';
        }

        const changedFilter = { };

        if (propertyName === "createDateFrom" || propertyName === "createDateTo") {
            if (typeof e.target.value === "string") {
                changedFilter[propertyName] = moment(e.target.value);
            }
        } 
        else if (propertyName === "autoRefresh") {
            if(e.target.checked){
                const id = setInterval(this.onFilterApply, 60000);
                this.setState(
                    {
                        intervalId: id
                    });
            }
            else{
                clearInterval(this.state.intervalId);
                this.setState({intervalId: null});
            }
        } 
        else {
            changedFilter[propertyName] = e.target.value;
        }
        
        this.updateFilters(changedFilter);
        this.clearHistoryState();
    }

    private onFilterClear() {
        this.updateFilters({ 
            correlationId: '',
            carrierBookingRequestId: '',
            process: '',
            capability: '',
            customer: '',
            processStatus: '',
            uniqueId: '',
            nextPageToken: '',
            createDateFrom: moment().startOf('day'),
            createDateTo: moment().startOf('hour').add(1, 'hour')
        });
        this.clearHistoryState();
    }

    private clearHistoryState()
    {
        this.props.history.replace('/operational', null);
    }

    private loadFilterDictionaries() {
        const filter: IOperationalFilter = Object.assign({}, this.props.filter);
        this.props.actions.loadFilterDictionaries(filter);
    }
}

const mapStateToProps = (state: IRootReducer, ownProps: any) => {
    return {
        isLoading: state.operationalView.isLoading,
        errorLoading: state.operationalView.errorLoading,
        loadingErrorMessage: state.operationalView.loadingErrorMessage,
        processes: state.operationalView.processes,
        filter: state.operationalView.filter || {},
        nextPageToken: state.operationalView.nextPageToken,        
        processesMoreFilters: state.operationalView.processesMoreFilters,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(operationalActions, dispatch)
    };
}

export default compose (
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(OperationalContainer);
