import {Component} from "react";
import {AdvancedError} from "./AdvancedError";
import * as React from "react";
import "../main/main.css";
import "./styles/ApplicationError.css";

export default class StandaloneErrorContainer extends Component<{error: AdvancedError}> {
    private error: Readonly<AdvancedError>;
    constructor(props: any) {
        super(props);
        this.error = this.props.error;
    }

    public render() {
        return (
            <div className="lsg-content">
                <header className="ds-header app-logged-in" />
                <div className="application-error">
                    <div className="message">
                        <h1 className="name">{this.error.name}</h1>
                        <h2 className="content">{this.error.friendlyMessage}</h2>
                    </div>
                    {this.error.details &&
                    <div className="stack">
                        <h2 className="error-code">{this.error.errorCode ? "Error Code: "+this.error.errorCode : ""}</h2>
                        <h3>Error details:</h3>
                        <span className="details">{this.error.details}</span>
                    </div>
                    }
                </div>
            </div>

        );
    }
}
