import {IMessageDetailsContainerState, IMessageDetailsItem, IOriginalPayloadQuery} from "../model/MessageDetailsModel";
import {MessageDetailsApi} from "../api/MessageDetailsApi";

import * as actionTypes from "./MessageDetailsActionTypes";
const api = new MessageDetailsApi();

export const loadItemSuccess = (view: IMessageDetailsContainerState) => {
    return {type: actionTypes.MESSAGEDETAILS_LOAD_ITEM_SUCCESS, view};
}

export const loadItemError = (view: IMessageDetailsContainerState) => {
    return {type: actionTypes.MESSAGEDETAILS_LOAD_ITEM_ERROR, view};
}

export const loadItemInProgress = (view: IMessageDetailsContainerState) => {
    return {type: actionTypes.MESSAGEDETAILS_LOAD_ITEM_IN_PROGRESS, view};
}

export const downloadStartFileAction = (view: IMessageDetailsContainerState) => {
    return { type: actionTypes.MESSAGEDETAILS_DOWNLOAD_FILE_START, view };
}

export const downloadEndFileAction = (view: IMessageDetailsContainerState) => {
    return { type: actionTypes.MESSAGEDETAILS_DOWNLOAD_FILE_END, view };
}

export const downloadErrorFileAction = (view: IMessageDetailsContainerState) => {
    return { type: actionTypes.MESSAGEDETAILS_DOWNLOAD_FILE_ERROR, view };
}

const createEmptyItem = (): IMessageDetailsItem => {
    return {
        id: '',
        createdDate: '',
        status: '',
        monitorMessage: '',
        correlationId: '',
        uniqueId: '',
        messageType: '',
        customer: '',
        systemName: '',
    };
}

export const loadItem = (id: string, viewState: IMessageDetailsContainerState) => {
    return (dispatch: any) => {
        dispatch(loadItemInProgress(
            {
                loading: true,
                data: createEmptyItem(),
                originalPayload: '',
                errorMessage: ''
            }));
        api.getItem(id)
            .then(response => {
                dispatch(loadItemSuccess(
                    {
                        loading: false,
                        data: response.data,
                        originalPayload: response.originalPayload,
                        errorMessage: ''
                    }));
            })
            .catch(error => {
                console.log(error);
                dispatch(loadItemError(
                    {
                        loading: false,
                        data: createEmptyItem(),
                        originalPayload: '',
                        errorMessage: 'An error has occurred'
                    }));
            });
    }
}


export const downloadFile = (query: IOriginalPayloadQuery) => {
    return (dispatch: any) => {

        dispatch(downloadStartFileAction(
        {
                loading: true,
                data: createEmptyItem(),
                originalPayload: '',
                errorMessage: ''
            }));

        api.downloadFile(query)
            .then(() => {
                dispatch(downloadEndFileAction(
                    {
                        loading: false,
                        data: createEmptyItem(),
                        originalPayload: '',
                        errorMessage: ''
                    }));
            })
            .catch(error => {
                dispatch(downloadErrorFileAction(
                    {
                        loading: false,
                        data: createEmptyItem(),
                        originalPayload: '',
                        errorMessage: 'An error has occurred while downloading file'
                    }));
            });
    }
}