import * as React from 'react';
import { InputFormGroup } from '../common/';
import Slider from "react-slick";
import './styles/slick.css';
import { ICapabilityLinksProps, ICapabilityProps } from './dashboardModel';

interface ICapabilityLinksState {
    originalCapabilities: ICapabilityProps[];
    filteredCapabilities?: ICapabilityProps[];
    filterText?: string;
}

export class CapabilityLinksSection extends React.Component<ICapabilityLinksProps, ICapabilityLinksState> {

    constructor(props: ICapabilityLinksProps){
        super(props);
        this.state = {
            originalCapabilities: this.props.capabilities,
            filteredCapabilities: this.props.capabilities,
            filterText: ""
        }
        this.onClear = this.onClear.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    public componentWillReceiveProps(nextProps: ICapabilityLinksProps) {
        if(nextProps.capabilities !== this.props.capabilities) {
            this.setState({originalCapabilities: nextProps.capabilities});
        }
        if(nextProps.capabilities !== this.props.capabilities) {
            this.setState({filteredCapabilities: nextProps.capabilities});
        }
    }

    public render() {
        const selectedCapabilities: string[] = [];
        const formGroupClassName = "col-20";
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.props.pageCount,
            slidesToScroll: this.props.pageCount
        };
        const inputFormGroup = () => {
            return(<InputFormGroup
                labelText=""
                name="systemName"
                value={this.state.filterText}
                placeholder="Capability name"
                formGroupClassName={formGroupClassName}
                onChange={this.onInputChange}
            />)
        };

        if(this.props.selectedCapability){
            selectedCapabilities.push(this.props.selectedCapability)
        }
        if(this.state.filteredCapabilities && this.state.filteredCapabilities.length > 0){
            const capabilityItems = this.state.filteredCapabilities.map((capability) =>
            <div key={capability.id}>
                <h3 className="slide-text" key={capability.name}>
                    <span className="fa fa-sitemap diagramIcon"/>
                        {capability.name}
                    <span className="fa fa-search searchIcon"/>
                    <input type="checkbox" value={capability.name} onChange={this.props.onChange} checked={selectedCapabilities.indexOf(capability.name) > -1} className="checkboxIcon" />
                </h3>
            </div>
        );
        return (
            <div className="grid-wrapper">
                <div className="row">
                    {inputFormGroup()}
                </div>
                <div className="row">
                    <Slider dots={settings.dots}
                            infinite={false}
                            speed={settings.speed}
                            slidesToShow={settings.slidesToShow}
                            slidesToScroll={settings.slidesToScroll}>
                            {capabilityItems}
                    </Slider>
                </div>
            </div>
        );
        }
        else{
            return(<div className="grid-wrapper">
            <div className="row">
                {inputFormGroup()}
            </div>
            <div className="row">
                Empty results
            </div>
        </div>)
        }
    }

    private onInputChange(e: any) {
        let currentCapabilities = this.state.originalCapabilities;
        if(e.target.value){
            currentCapabilities = currentCapabilities.filter(
                (value) => {
                    const check = value.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
                    return check;
                });
        }
        this.setState({
            originalCapabilities: this.state.originalCapabilities,
            filteredCapabilities: currentCapabilities,
            filterText: e.target.value
        });
    }

    private onClear() {
        if (this.props.onClear) {
            this.props.onClear();
        }
    }

    private onChange(e: any) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }
}