import * as React from "react";
import {IListBoxItem} from "src/common/dual-listbox/IListBoxItem";
import {ICustomerGroup, ICustomerGroupDifferences} from "src/api/customerGroups/models";
import "../styles/BeCodeAssignerItem.css";
import {IRootReducer} from "src/store/rootReducer";
import {connect} from "react-redux";
import {ListBoxItemContext} from "../../common/dual-listbox/listbox";
import {bindActionCreators} from "redux";
import * as configurationActions from "../store/CustomerGroup/Actions";


interface IBeCodeAssignerItemOwnProps {
    readonly: boolean;
}

interface IBeCodeAssignerItemProps  extends  IBeCodeAssignerItemOwnProps{
    differences: ICustomerGroupDifferences[];
    actions: typeof configurationActions;
    customerGroup: ICustomerGroup;
}

interface IBeCodeAssignerItemState {
    inProgress: boolean;
}

class BeCodeAssignerItem extends React.Component<IBeCodeAssignerItemProps,IBeCodeAssignerItemState> {
    public static contextType = ListBoxItemContext;

    private static renderDifferencesList(title: string, data: string[]): JSX.Element {
        const items = data.map(item => (<li key={item}>{item}</li>));
        if (items.length === 0) {
            return <></>
        }
        return (
            <div>
                <span>{title}:</span>
                <ul>
                    {items}
                </ul>
            </div>
        );
    }

    private static renderTooltip(difference: ICustomerGroupDifferences): JSX.Element {
        return (
            <ul>
                <li>{BeCodeAssignerItem.renderDifferencesList("Process missing in Customer", difference.processesMissingInCustomer)}</li>
                <li>{BeCodeAssignerItem.renderDifferencesList("Steps missing in Customer", difference.stepsMissingInCustomer)}</li>
                <li>{BeCodeAssignerItem.renderDifferencesList("Process missing in Customer Group", difference.processesMissingInCustomerGroup)}</li>
                <li>{BeCodeAssignerItem.renderDifferencesList("Steps missing in Customer Group", difference.stepsMissingInCustomerGroup)}</li>
            </ul>
        )
    }

    public constructor(props: IBeCodeAssignerItemProps) {
        super(props);
        this.onSync = this.onSync.bind(this);
        this.state = {
            inProgress: false
        }
    }

    public render() {
        return (
            <ListBoxItemContext.Consumer>
                {value => this.renderItem(value.item)}
            </ListBoxItemContext.Consumer>
        )
    }

    private renderItem(item: IListBoxItem) {
        return (
            <div className={"becode-assign-item__container"}>
                <span>{item.label}</span>
                {this.renderInfo(item)}
            </div>
        );
    }

    private renderInfo(item: IListBoxItem): JSX.Element {
        const difference = this.findDifferences(item.label);
        if (!difference || difference.synced) {
            return <></>
        }
        return (
            <>
                {this.renderAction(item)}
                <div className={"becode-assign-item__tooltip"}>
                    <div
                        className={"becode-assign-item__tooltip-text"}>{BeCodeAssignerItem.renderTooltip(difference!)}</div>
                </div>
            </>
        )
    }

    private renderButton(item: IListBoxItem): JSX.Element {
        if(this.props.readonly) {
            return <></>
        }
        return (
        <button type="button" className={"button button-context"} disabled={this.state.inProgress} onClick={
            this.onSync(item.label)}>
            Sync
        </button>
        )
    }

    private renderAction(item: IListBoxItem): JSX.Element {
        if (!this.props.differences) {
            return <></>
        }
        return (
            <div className={"becode-assign-item__action"}>
                <i className="fas fa-info-circle" style={{color: "red"}}/>
                {this.renderButton(item)}
            </div>
        )
    }

    private onSync(customer: string) {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            if(this.props.readonly) {
                return;
            }
            const customerGroup = this.props.customerGroup.name;
            const confirmation = confirm(`Do you want to synchronize ${customer} with ${customerGroup} (this cannot be undone)? `);
            if(confirmation) {
                this.setState({inProgress: true}, () =>
                    this.props.actions.synchronizeCustomerWithGroup(customerGroup, customer)
                );
            }
        };
    }



    private findDifferences(customer: string): ICustomerGroupDifferences | undefined {
        return this.props.differences.find(diff => diff.beCode.toLowerCase() === customer.toLowerCase());
    }
}



const mapStateToProps = (state: IRootReducer, ownProps: IBeCodeAssignerItemOwnProps) => {
    const props: Partial<IBeCodeAssignerItemProps> = {
        customerGroup: state.groupConfigurationView.selectedCustomerGroup,
        differences: state.groupConfigurationView.customerGroupDifferences,
        readonly: ownProps.readonly
    }
    return props;
}


const mapDispatchToProps = (dispatch: any) => {
    const props: Partial<IBeCodeAssignerItemProps> = {
        actions: bindActionCreators(configurationActions, dispatch)
    }
    return props;
}


export default connect(mapStateToProps, mapDispatchToProps)(BeCodeAssignerItem);