import * as React from 'react';
import { Column } from 'react-table';
import { ColumnTheme, setStyles } from "../data-table/utils/columnStyles";
import { header } from './Header';

export const ColumnWithCustomCell = (
    accessor: string,
    name: string,
    cell: (row: any) => React.ReactNode,
    config: {
        theme?: ColumnTheme,
        resizable?: boolean,
    } = {},
): Column => {
    return {
        resizable: config.resizable,
        headerClassName: 'data-table header',
        className: '-status',
        Header: (rowInfo) => header(rowInfo, name),
        accessor,
        style: setStyles(config.theme),
        Cell: cell,
        show: true
    }
}