import { IOperationalFilter } from "./OperationalApi";
import * as actionTypes from './operationalActionsTypes'
import { IOperationalFilterState } from "./OperationalContainer";
import { OperationalApiService, IProcessResult } from "./OperationalApiService";
import * as moment from 'moment';

export const loadError = () => {
    return { type: actionTypes.OPERATIONAL_LOAD_ERROR, result: { errorLoading: true } };
}

export const loadInProgress = () => {
    return { type: actionTypes.OPERATIONAL_LOAD_IN_PROGRESS };
}

export const loadProcessesSuccess = (result: IProcessResult) => {
    return { type: actionTypes.OPERATIONAL_LOAD_PROCESSES_SUCCESS, 
        result };
}

export const loadProcessesNextPageSuccess = (result: IProcessResult) => {
    return { type: actionTypes.OPERATIONAL_LOAD_PROCESSES_NEXT_PAGE_SUCCESS, 
        result };
}

export const loadFilterDictionariesSuccess = (filter: IOperationalFilterState) => {
    return { type: actionTypes.OPERATIONAL_LOAD_FILTER_DICTIONARIES, filter };
}

export const clearResults = () => {
    return { type: actionTypes.OPERATIONAL_CLEAR_RESULTS };
}

export const loadProcesses = (filters: IOperationalFilter, processesMoreFilters: boolean) => {
    return (dispatch: any) => {
        dispatch(loadInProgress());
        const api = new OperationalApiService();

        if(!processesMoreFilters){
            filters = { 
                correlationId: filters.correlationId,
                carrierBookingRequestId: '',
                process: '',
                capability: '',
                customer: '',
                processStatus: '',
                uniqueId: '',
                nextPageToken: '',
                createDateFrom: moment().startOf('day'),
                createDateTo: moment().startOf('hour').add(1, 'hour')
            };
        }

        api.getProcessesInstances(filters)
            .then((result) => {
                if (filters.nextPageToken && filters.nextPageToken.length > 0) {
                    dispatch(loadProcessesNextPageSuccess(result));
                }
                else {
                    dispatch(loadProcessesSuccess(result));
                }
            })
            .catch(error => {
                dispatch(loadError());
            });
    };
}

export const loadFilterDictionaries = (filter: IOperationalFilter) => {
    return (dispatch: any) => {
        dispatch(loadInProgress());
        const api = new OperationalApiService();
        api.getFilterDictionaries()
            .then((result) => {
                dispatch(loadFilterDictionariesSuccess(result));
            })
            .catch(error => {
                dispatch(loadError());
            });
    };
}

export const changeFilterToProcesses = () => {
    return { type: actionTypes.OPERATIONAL_CHANGE_FILTER_TO_PROCESSES };
}

export const changeMoreFiltersOnProcesses = (currentMoreFilters: boolean) => {
    return { type: actionTypes.OPERATIONAL_CHANGE_PROCESSES_MORE_FILTERS, currentMoreFilters };
}

export const changeFilterState = (newFilters: IOperationalFilterState) => {
    return { type: actionTypes.OPERATIONAL_CHANGE_FILTERS, newFilters};
}

export const downloadStartAllFilesAction = () => {
    return { type: actionTypes.OPERATIONAL_DOWNLOAD_ALL_FILES_START };
}

export const downloadEndAllFilesAction = () => {
    return { type: actionTypes.OPERATIONAL_DOWNLOAD_ALL_FILES_END };
}

export const downloadErrorAllFilesAction = () => {
    return { type: actionTypes.OPERATIONAL_DOWNLOAD_ALL_FILES_ERROR };
}

export const downloadAllFiles = (ids: string[]) => {
    return (dispatch: any) => {

        dispatch(downloadStartAllFilesAction());
        const api = new OperationalApiService();
        api.downloadAllFiles(ids)
            .then(() => {
                dispatch(downloadEndAllFilesAction());
            })
            .catch(error => {
                dispatch(downloadErrorAllFilesAction());
            });
    }
}