import * as React from "react"
import "../styles/NodeLabel.css"

export const StepTreeNodeLabel = (props: any) => {
	{
		const { nodeData } = props
		return (
			<div className="nodeLabel">
				<span className="nodeNameBase">{nodeData.name}</span>
				<span className="nodeAttributesBase">
					<small>messageType: {nodeData.messageType}</small>
				</span>
			</div>
		)
	}
}
