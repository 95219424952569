import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as React from "react";
import Notifications from "react-notify-toast";

import {IRootReducer} from "../../store/rootReducer";
import * as configurationActions from "src/configuration/store/Customer/Actions";

import {LoadingAnimation, PageHeader} from "src/common";

import CustomerSelector from "../components/CustomerSelector";
import CapabilitiesList from "../components/CapabilitiesList";
import ProcessConfiguration from "../components/ProcessConfiguration";
import {ICustomerProcessDefinition} from "../models/ICustomerProcessDefinition";
import {filterCapabilities} from "../helpers";
import {ICustomerConfigurationState} from "../store/Customer"
import { ICapability, ICustomer, IProcessDefinition } from "src/api/models";


interface ICustomerConfigurationProps {
    actions: typeof configurationActions;
    capabilities: ICapability[];
    customer: ICustomer;
    processDefinition: IProcessDefinition;
    isLoading: boolean;
}

class CustomerConfiguration extends React.Component<ICustomerConfigurationProps> {
    constructor(props: ICustomerConfigurationProps) {
        super(props);
        this.onProcessClick = this.onProcessClick.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
    }

    public render() {
        return (
            <>
                <PageHeader title="Customer Details"/>
                <div className="page-container">
                    <div className="grid-wrapper">
                        <Notifications/>
                        {this.props.isLoading && <LoadingAnimation/>}
                        <CustomerSelector/>
                        <div className="col-30">
                            <CapabilitiesList
                                owner={this.props.customer}
                                onProcessClicked={this.onProcessClick}
                                capabilities={this.filterCapabilities()}/>
                        </div>
                        <div className="col-70">
                            <ProcessConfiguration
                                onSaveChanges={this.onSaveChanges}
                                owner={this.props.customer}
                                processDefinition={this.props.processDefinition}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    public componentDidMount() {
        this.props.actions.loadCapabilities();
    }

    private onProcessClick(processName: string): void {

        this.props.actions.loadProcessDefinition(processName, this.props.customer.name);
    }

    private filterCapabilities(): ICapability[] {
        const customer = this.props.customer;
        if (customer == null || customer.processIds == null) {
            return [];
        }
        return filterCapabilities(this.props.capabilities, this.props?.customer.processIds);
    }

    private onSaveChanges(processDefinition: ICustomerProcessDefinition) {
        if (this.props.customer == null) {
            return;
        }
        const customer = this.props.customer as ICustomer;

        const processIds = customer.processIds?.map(process => {
            if (processDefinition?.name === process.id) {
                return {...process, enabled: processDefinition.enabled}
            } else {
                return process
            }
        })

        const steps = customer.steps?.map(step => {
            const processStep = processDefinition?.steps.find((s: any) => s.messageType === step.id)
            if (processStep !== undefined) {
                return {...step, enabled: processStep.enabled}
            } else {
                return step
            }
        })
        if (!processIds || !steps) {
            return;
        }

        this.props.actions.saveCustomer({...customer, processIds, steps})

    }

}


const mapStateToProps = (state: IRootReducer) => {
    const localState: ICustomerConfigurationState = state.configurationView;
    const props: Partial<ICustomerConfigurationProps> = {
        isLoading: localState.isLoadingData,
        capabilities: localState.capabilities ?? [],
        customer: localState.customer,
        processDefinition: localState.processDefinition
    }

    return props;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(configurationActions, dispatch)
    }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CustomerConfiguration);