import * as React from "react";
import {connect} from "react-redux";
import {Button, ButtonType} from "src/common/button";
import {mapProcessDefinition} from "../helpers";
import ProcessDefinitionTable from "./ProcessDefinitionTable";
import StepsTree, {IStepElement} from "./StepsTree";
import {ProcessChangeHistory} from "./ProcessChangeHistory";

import {IRootReducer} from "src/store/rootReducer";
import {IUserPermissions} from "src/main/main";
import {ICustomerProcessDefinition} from "../models/ICustomerProcessDefinition";
import { PERMISSIONS_CONFIGURE_FLOWS } from "src/common/UserPermissions";
import {ICustomer, ICustomerGroup, IProcessDefinition, IStepDefinition } from "src/api/models";


interface IProcessConfigurationOwnProps {
    owner: ICustomerGroup | ICustomer;
    processDefinition: IProcessDefinition | null;
    onSaveChanges: (processDefinition: ICustomerProcessDefinition) => void;
}


interface IProcessConfigurationProps extends IProcessConfigurationOwnProps{
    userPermissions: IUserPermissions;
}

export interface IProcessConfigurationState {
    modelChanged: boolean;
    customerProcessDefinition: ICustomerProcessDefinition | null;
}

class ProcessConfiguration extends React.Component<IProcessConfigurationProps, IProcessConfigurationState> {
    constructor(props: IProcessConfigurationProps) {
        super(props);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.onProcessStatusChange = this.onProcessStatusChange.bind(this);
        this.onStepStatusChange = this.onStepStatusChange.bind(this);
        this.mapProcessDefinition = this.mapProcessDefinition.bind(this);
        this.state = {
            modelChanged: false,
            customerProcessDefinition: null
        }
    }

    public componentDidUpdate(prevProps: IProcessConfigurationProps) {
        if (prevProps.processDefinition?.name !== this.props.processDefinition?.name) {
            this.mapProcessDefinition()
        }
    }

    public render() {
        if (!this.props.processDefinition) {
            return <div/>
        }
        return (
            <>
                <ProcessDefinitionTable onStatusChange={this.onProcessStatusChange}
                                        readonly={!this.userCanModifyProcess()}
                                        processDefinition={this.state.customerProcessDefinition}
                                        processOwnerName={this.props.owner.name}
                />

                <ProcessChangeHistory changeHistory={[]}/>

                {this.renderSaveChanges()}

                <StepsTree
                    readonly={!this.userCanModifyProcess()}
                    onTreeNodeClick={this.onStepStatusChange}
                    processDefinition={this.state.customerProcessDefinition}
                />
            </>
        );
    }

    private renderSaveChanges(): JSX.Element {
        if (this.userCanModifyProcess()) {
            return (
                <Button buttonType={ButtonType.Blue} isDisabled={!this.state.modelChanged} onClick={this.onSaveChanges}>
                    Save changes
                </Button>
            )
        }
        return <></>
    }

    // region: Process Definition change events
    private onStepStatusChange(elements: IStepElement[], enabled: boolean) {
        if (!this.state.customerProcessDefinition) {
            return;
        }
        const processDefinition = this.state.customerProcessDefinition as ICustomerProcessDefinition;

        const stepNames = elements.map((el: any) => el.name);
        const steps = processDefinition.steps.map((step: IStepDefinition) => {
            if (stepNames.indexOf(step.name) > -1) {
                return {...step, enabled}
            } else {
                return step
            }
        });

        const newProcessDefinition = {...processDefinition, steps};

        this.updateLocalProcessDefinition(newProcessDefinition);
    }

    private onProcessStatusChange(enabled: boolean): void {
        if (!this.state.customerProcessDefinition) {
            return;
        }
        const processDefinition = this.state.customerProcessDefinition as ICustomerProcessDefinition;

        const newProcessDefinition = {...processDefinition, enabled};
        this.updateLocalProcessDefinition(newProcessDefinition);

    }

    private updateLocalProcessDefinition(newProcessDefinition: ICustomerProcessDefinition) {
        this.setState(
            {customerProcessDefinition: newProcessDefinition},
            () => {
                this.setState({modelChanged: this.processDefinitionHasChanged()});
            }
        );

    }

    private processDefinitionHasChanged(): boolean {
        const owner = this.props.owner;
        const process = owner.processIds?.find(p => p.id === this.state.customerProcessDefinition?.name)
        if (this.state.customerProcessDefinition?.enabled !== process!.enabled) {
            return true;
        }

        return !this.state.customerProcessDefinition?.steps.every((p: IStepDefinition) => {
            const step = owner.steps?.find(s => s.id === p.messageType);
            return step === undefined || step!.enabled === p.enabled
        })
    }
    // endregion

    private mapProcessDefinition() {
        if(!this.props.processDefinition) {
            return;
        }
        const customerProcessDefinition = mapProcessDefinition(this.props.processDefinition, this.props.owner);
        this.setState({customerProcessDefinition});
    }

    private userCanModifyProcess() {
        return this.props.userPermissions.businessFunctions.indexOf(PERMISSIONS_CONFIGURE_FLOWS) !== -1;
    }

    private onSaveChanges() {
        if(this.state.customerProcessDefinition) {
            this.props.onSaveChanges(this.state.customerProcessDefinition);
        }
    }
}

const mapStateToProps = (state: IRootReducer, ownProps: IProcessConfigurationOwnProps) => {
    const props: Partial<IProcessConfigurationProps> = {
        userPermissions: state.app.userPermissions,
        owner: ownProps.owner,
        processDefinition: ownProps.processDefinition,
        onSaveChanges: ownProps.onSaveChanges
    }
    return props;
}


export default connect(mapStateToProps)(ProcessConfiguration);