import {combineReducers} from "redux"
import {IDashboardContainer} from "../dashboard/DashboardContainer"
import {dashboardMainViewReducer as dashboardView} from "../dashboard/dashboardReducers"
import {processDetailsMainViewReducer as processDetailsView} from "../operational/processDetails/processDetailsReducers"
import {IOperationalContainer} from "../operational/OperationalContainer"
import {operationalReducer as operationalView} from "../operational/operationalReducers"
import {IUserActivityContainer} from "../userActivity/UserActivityContainer"
import {userActivityReducer as userActivityView} from "../userActivity/userActivityReducers"
import {IApp} from "src/main/main"
import {mainReducer as app} from "src/main/mainReducer"
import {IGroupsContainer} from "src/groups/GroupsContainer";
import {groupsViewReducer as groupsView} from "../groups/GroupsReducer";
import {rejectedMessagesContainerReducer as monitorView} from "../rejectedMessages/RejectedMessagesContainerReducer";
import {IMessageDetailsContainerState} from "../rejectedMessages/messageDetails/model/MessageDetailsModel";
import {messageDetailsReducer as messageDetailsView} from "../rejectedMessages/messageDetails/MessageDetailsReducer";
import {IMonitorContainer} from "../common/monitor/model/monitor";
import {CustomerConfigReducer as configurationView, ICustomerConfigurationState} from "src/configuration/store/Customer"
import {
    CustomerGroupConfigReducer as groupConfigurationView,
    ICustomerGroupConfigurationState
} from 'src/configuration/store/CustomerGroup';

export interface IRootReducer {
    app: IApp
    dashboardView: IDashboardContainer
    processDetailsView: any
    operationalView: IOperationalContainer
    monitorView: IMonitorContainer
    configurationView: ICustomerConfigurationState
    userActivityView: IUserActivityContainer
    groupsView: IGroupsContainer;
    messageDetailsView: IMessageDetailsContainerState;
    groupConfigurationView: ICustomerGroupConfigurationState;
}

export const rootReducer = combineReducers<IRootReducer>({
    app,
    dashboardView,
    processDetailsView,
    operationalView,
    monitorView,
    configurationView,
    userActivityView,
    groupsView,
    messageDetailsView,
    groupConfigurationView
})
