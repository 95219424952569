import {Action} from "redux";
import {ICustomerConfigurationState} from "./State";

export interface ICustomerConfigReducerAction extends Action<string> {
    newState: Partial<ICustomerConfigurationState>;
}

export default function CustomerConfigReducer(
    state: ICustomerConfigurationState, action: ICustomerConfigReducerAction): ICustomerConfigurationState {
    return {...state, ...action.newState};
}