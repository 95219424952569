import * as actionTypes from './operationalActionsTypes';
import { IOperationalContainer } from './OperationalContainer';
import { IProcess } from './OperationalApi';
import * as moment from 'moment';

export const operationalReducer = (state: IOperationalContainer = {    
    processesMoreFilters: true,
    filter: {
        correlationId: '',
        carrierBookingRequestId: '',
        process: '',
        capability: '',
        customer: '',
        processStatus: '',
        uniqueId: '',
        nextPageToken: '',
        createDateFrom: moment().startOf('day'),
        createDateTo: moment().startOf('hour').add(1, 'hour')
        }
} as IOperationalContainer, action: any): IOperationalContainer => {

    switch(action.type) {
        case actionTypes.OPERATIONAL_LOAD_IN_PROGRESS:
            return { ...state, isLoading: true };

        case actionTypes.OPERATIONAL_LOAD_ERROR:
            return { ...state,  errorLoading: true };
        
        case actionTypes.OPERATIONAL_LOAD_PROCESSES_SUCCESS:
            return { ...state, isLoading: false, processes: action.result.processes, nextPageToken: action.result.nextPageToken };

        case actionTypes.OPERATIONAL_LOAD_PROCESSES_NEXT_PAGE_SUCCESS:
            const processes: IProcess[] = state.processes.concat(action.result.processes);
            return { ...state, isLoading: false, processes, nextPageToken: action.result.nextPageToken };
        
        case actionTypes.OPERATIONAL_LOAD_FILTER_DICTIONARIES:
            return { ...state, isLoading: false, filter: {...state.filter, ...action.filter }};
        
        case actionTypes.OPERATIONAL_CLEAR_RESULTS:
            return { ...state, processes: [], nextPageToken: ''};            
        
        case actionTypes.OPERATIONAL_CHANGE_PROCESSES_MORE_FILTERS:
            return { ...state, processesMoreFilters: !action.currentMoreFilters };        

        case actionTypes.OPERATIONAL_CHANGE_FILTERS:
            return {...state, filter: {...state.filter, ...action.newFilters}};

        case actionTypes.OPERATIONAL_DOWNLOAD_ALL_FILES_START:
            return { ...state, isLoading: true }
        
        case actionTypes.OPERATIONAL_DOWNLOAD_ALL_FILES_END:
            return { ...state, isLoading: false }
        
        case actionTypes.OPERATIONAL_DOWNLOAD_ALL_FILES_ERROR:
            return { ...state, isLoading: false };
    }

    return { ...state };
}