import * as React from "react";
import { Component } from "react";
import DualListBox from "react-dual-listbox";
import { IProductGroup } from "./GroupModels";
import { confirmMessageAlert } from "src/common/messages/confirmAlertMessage";

interface ICustomerGroupDetails {
  selectedGroup?: IProductGroup;
  selectedItems: string[];
  availableItems: string[];
  modelChanged?: boolean;
  headers: {
    availableHeader: string;
    selectedHeader: string;
  };
  deleteButtonVisibility: boolean;
  onCustomerListChanged: (event: any) => void;
  onSaveButtonClick: () => void;
  onInputValueChange: (event: any) => void;
  onRowDeleteClick: () => any;
}

export class SelectedItemDetails extends Component<ICustomerGroupDetails, any> {
  constructor(props: any) {
    super(props);

    if (props.selectedGroup) {
      this.state = {
        hasError: !props.selectedGroup.name,
      };
    }
  }

  public componentWillReceiveProps(nextProps: ICustomerGroupDetails) {
    if (nextProps.selectedGroup) {
      this.validateName(nextProps.selectedGroup.name);
    }
  }

  public handleOnSaveClick = () => {
    this.props.onSaveButtonClick();
  };

  public handleListBoxChanges = (e: any) => {
    this.props.onCustomerListChanged(e);
  };

  public handleDeleteClick = () => {
    confirmMessageAlert(
      "Are you sure?",
      "This action will permanently delete the group!",
      () => {
        this.props.onRowDeleteClick();
      }
    );
  };

  public handleChange = (e: any) => {
    this.props.onInputValueChange(e.target.value);
  };

  public render() {
    const lang = {
      moveLeft: "Move left",
      moveAllLeft: "Move all left",
      moveRight: "Move right",
      moveAllRight: "Move all right",
      availableHeader: this.props.headers.availableHeader,
      selectedHeader: this.props.headers.selectedHeader,
    };

    return (
      <>
        <div className="details-container">
          <div>
            <table className="details-table">
              <tbody>
                {this.props.selectedGroup != null ? (
                  <tr>
                    <td className="gr-name-edition-title">
                      <b>Group name: </b>
                    </td>
                    <td>
                      <input
                        className={
                          this.state.hasError
                            ? "gr-name-input error"
                            : "gr-name-input"
                        }
                        type="text"
                        value={this.props.selectedGroup.name}
                        onChange={this.handleChange}
                      />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td className="gr-name-edition-title" />
                  </tr>
                )}
                <tr>
                  <th colSpan={3}>
                    {
                      <DualListBox
                        options={
                          this.props.availableItems
                            ? this.props.availableItems.map((x) => {
                                return { value: x, label: x };
                              })
                            : []
                        }
                        selected={
                          this.props.selectedItems
                            ? this.props.selectedItems
                            : []
                        }
                        onChange={this.handleListBoxChanges}
                        canFilter="true"
                        showHeaderLabels="false"
                        lang={lang}
                      />
                    }
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="details-buttons">
            <button
              type="button"
              className="button button-blue"
              onClick={this.handleOnSaveClick}
              disabled={!this.props.modelChanged}
            >
              Save
            </button>
            {this.props.deleteButtonVisibility ? (
              <button
                type="button"
                className="button button-blue delete-btn"
                onClick={this.handleDeleteClick}
              >
                Delete
              </button>
            ) : null}
          </div>
        </div>
      </>
    );
  }
  private validateName(value: string) {
    if (value.match(/^[0-9a-zA-Z \b]+$/)) {
      this.setState({ hasError: false });
    } else {
      this.setState({ hasError: true });
    }
  }
}
