import * as React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import OperationalContainer from "../operational/OperationalContainer"
import ProcessDetailsContainer from "../operational/processDetails/ProcessDetailsContainer"
import DashboardContainer from "../dashboard/DashboardContainer"
import ErrorContainer from "../error/ErrorContainer"
import { BRAIN_ERROR_URL } from "src/error/ErrorConstants"
import UserActivityContainer from "src/userActivity/UserActivityContainer"
import GroupsContainer from "src/groups/GroupsContainer";
import RejectedMessageContainer from "../rejectedMessages/components/RejectedMessagesContainer";
import MessageDetailsContainer from "../rejectedMessages/messageDetails/components/MessageDetailsContainer";
import GroupConfigurationContainer from "../configuration/pages/CustomerGroupConfiguration";
import CustomerConfiguration from "../configuration/pages/CustomerConfiguration";
import CustomerInGroupDetails from "../configuration/pages/CustomerInGroupDetails";

export const RoutingConfiguration = () => {
	return (
		<main className="full-width">
			{/* Warning! order is important if you use the Switch component */}
			<Switch>
				<Route path="/operational/process/:id" component={ProcessDetailsContainer} />
				<Route path="/configuration/customer-in-group-details/:id" component={CustomerInGroupDetails} />
				<Route path="/configuration/customer-groups" component={GroupConfigurationContainer} />
				<Route path="/configuration" component={CustomerConfiguration} />
				<Route path="/dashboard" component={DashboardContainer} />
				<Route path="/operational" component={OperationalContainer} />
				<Route path="/rejected-messages/details/:id" component={MessageDetailsContainer} />
				<Route path="/rejected-messages" component={RejectedMessageContainer} />
				<Route path="/user-activity" component={UserActivityContainer} />
				<Route path="/groups" component={GroupsContainer} />
				<Route path={BRAIN_ERROR_URL} component={ErrorContainer} />
				<Redirect from="/" to="operational" /> :
			</Switch>
		</main>
	)
}
