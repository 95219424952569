import * as React from 'react';
import { Link } from 'react-router-dom';
import { IOperationalFilter } from '../operational/OperationalApi';

interface IDoughnutLink {
    isLinked: boolean;
    name: string;
}

export const   DoughnutLinks = (props: IDoughnutLink) => {
    const state: IOperationalFilter = {
        capability: props.name
    }

    const pathParams = {
        pathname: '/operational',
        state
    }
    if(props.isLinked === true){
        return(
            <h2>
                <Link to={pathParams}>{props.name}</Link>
            </h2>
        );
    }
    else{
        return(
            <h2>
                {props.name}
            </h2>
        );
    }
    
}