import * as React from "react";
import { ICustomer } from "./GroupModels";
import Accordion from "src/common/Accordion";
import ReactTable from "react-table";
import { ColumnFactory } from "src/common/data-table/ColumnFactory";
import { Component } from "react";
import { confirmMessageAlert } from "src/common/messages/confirmAlertMessage";

interface ICustomersTableContainer {
  customers: ICustomer[];
  isReadOnly: boolean;
  onRowDeleteClick: (event: any) => any;
  onEditCustomersClick: (event: any) => void;
}

export class CustomersTable extends Component<ICustomersTableContainer, any> {
  constructor(props: any) {
    super(props);
  }

  public handleOnRowDeleteClick = (event: any) => (val: any) => {
    confirmMessageAlert(
      "Are you sure?",
      "This customer will no longer be available on this product!",
      () => {
        this.props.onRowDeleteClick(event);
      }
    );
  };

  public render() {
    const columns = [ColumnFactory.standardColumn("beCode", "Customers")];

    if (this.props.isReadOnly) {
      columns.push({
        width: 130,
        Cell: (value) => (
          <a id="dt-buttons" onClick={this.handleOnRowDeleteClick(value)}>
            Remove
          </a>
        ),
      });
    }

    const manageButtons = this.props.isReadOnly ? (
      <>
        <button
          type="button"
          className="button button-blue header-button"
          onClick={this.props.onEditCustomersClick}
        >
          Edit
        </button>
      </>
    ) : null;

    return (
      <>
        {
          <Accordion title={"Customers"} key={"customers"} isExpanded={true}>
            {manageButtons}
            <br />
            <div className="data-table-container modal-data-table">
              <ReactTable
                className="-striped -highlight"
                data={this.props.customers}
                columns={columns}
                sortable={false}
                minRows={1}
                defaultPageSize={5}
                pageSizeOptions={[5, 10, 15]}
              />
            </div>
          </Accordion>
        }
      </>
    );
  }
}
