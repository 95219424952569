import * as React from "react";
import { IFlowItem } from "src/api/models";


/** @type {{search: React.CSSProperties}} */
const blockStyle = {
    width: '14px',
    height: '14px',
    display: 'inline-block'
} as React.CSSProperties;


interface IProcessStatusIndicatorProps {
    process: string;
    ownerProcesses: IFlowItem[];
}


export default class ProcessStatusIndicator extends React.Component<IProcessStatusIndicatorProps> {
    constructor(props: IProcessStatusIndicatorProps) {
        super(props);
    }

    public render() {
        const className: string = this.getStatusClass();
        return <span style={blockStyle} className={className} />
    }

    private getStatusClass(): string {
        if(this.isEnabled()) {
            return "green";
        }
        else if(this.someStepsEnabled()) {
            return "amber";
        }
        return "red";

    }

    private getProcess(): IFlowItem | null {
        const processes: IFlowItem[] = this.props.ownerProcesses.filter(
            (item: IFlowItem) => item.id === this.props.process
        );
        if(!processes || processes.length === 0) { return null; }

        return processes[0];
    }

    // this works only if API returns information about anyStepsDisabled
    private someStepsEnabled(): boolean {
        const process: IFlowItem | null = this.getProcess();
        if(!process) {
            return false;
        }
        return process.enabled && process.anyStepsDisabled;
    }

    private isEnabled(): boolean {
       const process: IFlowItem | null = this.getProcess();
        if(!process) {
            return false;
        }
        return process.enabled;
    }
}