export const PROCESSDETAILS_LOAD_ITEM_SUCCESS = 'PROCESSDETAILS_LOAD_ITEM_SUCCESS';
export const PROCESSDETAILS_LOAD_ITEM_ERROR = 'PROCESSDETAILS_LOAD_ITEM_ERROR';
export const PROCESSDETAILS_LOAD_ITEM_IN_PROGRESS = 'PROCESSDETAILS_LOAD_ITEM_IN_PROGRESS';
export const PROCESSDETAILS_SHOW_RESENDPANEL = 'PROCESSDETAILS_SHOW_RESENDPANEL';
export const PROCESSDETAILS_HIDE_RESENDPANEL = 'PROCESSDETAILS_HIDE_RESENDPANEL';
export const PROCESSDETAILS_RESEND_START = 'PROCESSDETAILS_RESEND_START';
export const PROCESSDETAILS_RESEND_CANCEL = 'PROCESSDETAILS_RESEND_CANCEL';
export const PROCESSDETAILS_RESEND_PROCESSING = 'PROCESSDETAILS_RESEND_PROCESSING';
export const PROCESSDETAILS_RESEND_END = 'PROCESSDETAILS_RESEND_END';
export const PROCESSDETAILS_DOWNLOAD_FILE_START = "PROCSSDETAILS_DOWNLOAD_FILE_START";
export const PROCESSDETAILS_DOWNLOAD_FILE_END = "PROCSSDETAILS_DOWNLOAD_FILE_END";
export const PROCESSDETAILS_DOWNLOAD_FILE_ERROR = "PROCSSDETAILS_DOWNLOAD_FILE_ERROR";
export const PROCESSDETAILS_DOWNLOAD_ALL_FILES_START = "PROCSSDETAILS_DOWNLOAD_ALL_FILES_START";
export const PROCESSDETAILS_DOWNLOAD_ALL_FILES_END = "PROCSSDETAILS_DOWNLOAD_ALL_FILES_END";
export const PROCESSDETAILS_DOWNLOAD_ALL_FILES_ERROR = "PROCSSDETAILS_DOWNLOAD_ALL_FILES_ERROR";
export const PROCESSDETAILS_RESEND_PROCESS_START = "PROCESSDETAILS_RESEND_PROCESS_START";
export const PROCESSDETAILS_RESEND_PROCESS_END = "PROCESSDETAILS_RESEND_PROCESS_END";
export const PROCESSDETAILS_RESEND_PROCESS_ERROR = "PROCESSDETAILS_RESEND_PROCESS_ERROR";