import * as actionTypes from "./mainActionTypes"
import { MainApiService } from "./MainApiService"
import { IUserPermissions } from "./main"

export const loadError = () => {
	return { type: actionTypes.MAIN_LOAD_ERROR, result: { errorLoading: true } }
}

export const loadInProgress = () => {
	return { type: actionTypes.MAIN_LOAD_IN_PROGRESS }
}

export const loadUserPermissionsSuccess = (result: IUserPermissions) => {
	return { type: actionTypes.MAIN_LOAD_USERPERMISSIONS_SUCCESS, result }
}

export const loadUserPermissions = () => {
	return (dispatch: any) => {
		dispatch(loadInProgress())
		const api = new MainApiService()
		api
			.getProcessesInstances()
			.then(result => {
				dispatch(loadUserPermissionsSuccess(result))
			})
			.catch(error => {
				dispatch(loadError())
			})
	}
}
