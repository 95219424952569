import * as React from 'react';
import './styles/strip.css';

interface IStripProps {
    key?: string;
    onSelect?: () => void;
    children?: any;
}

export const Strip = (props: IStripProps) => {

    return (
        <div className="strip" onClick={props.onSelect}>
            {props.children}
        </div>
    );
}