import {ApiServiceBase} from "../../common/ApiServiceBase";
import {ICapability} from "./models";

const CAPABILITIES_URL = '/capabilities';

export default class CapabilitiesApi extends ApiServiceBase {
    public getCapabilities(): Promise<ICapability[]> {
        return Promise.all([
            this.get(CAPABILITIES_URL)
        ])
            .then(items => items[0]);
    }
}