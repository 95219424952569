import * as React from 'react';
import {CheckboxFormGroup, FilterSection} from "../../index";
import {IMonitorFilterSectionProps} from "../model/filtering";

export abstract class MonitorFilterSection extends React.Component<IMonitorFilterSectionProps> {
    constructor(props: IMonitorFilterSectionProps) {
        super(props);

        this.onClear = this.onClear.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    public render () {
        const formGroupClassName = "col-25";
        return (
            <FilterSection
                onApply={this.props.onApply} onClear={this.onClear} quickFilterTitle="Correlation ID"
                showDownloadAll={false}
                quickFilterValue={this.props.correlationId} onChange={this.onChange}
                moreFilters={this.props.moreFilters} onMoreFilters={this.props.onMoreFilters}>
                { this.renderFilters() }
                <div className="grid-wrapper">
                    <CheckboxFormGroup
                        name={"autoRefresh"}
                        labelText="Auto refresh"
                        placeholder="Auto refresh"
                        formGroupClassName={formGroupClassName}
                        onChange={this.onChange}
                        value={"true"}
                    />
                </div>
            </FilterSection>
        );
    }
    protected abstract renderFilters(): JSX.Element | undefined;


    protected onClear() {
        if (this.props.onClear) {
            this.props.onClear();
        }
    }

    protected onChange(e: any) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }
}