import { ApiServiceBase } from "../common/ApiServiceBase";
import { IProduct, ICustomer, IProductGroup } from "./GroupModels";
import { IDropDownItem } from "src/common";

const GET_PRODUCTS = "/products";
const GET_GROUPS = "/groups";
const GET_CUSTOMERS = "/customers";

export class GroupsApiService extends ApiServiceBase {
  public getProducts(): Promise<IDropDownItem[]> {
    return this.getDropDownItems(GET_PRODUCTS, {
      key: "",
      text: "Select product",
      disabled: true,
    });
  }

  public getAllCustomers(): Promise<ICustomer[]> {
    return Promise.all([this.get(GET_CUSTOMERS)]).then((items: any) => {
      const customers = items[0].map((x: any) => {
        return { id: x.value, beCode: x.value };
      });
      return new Promise<ICustomer[]>((resolve) => {
        return resolve(customers);
      });
    });
  }

  public getAllGroups(): Promise<IProductGroup[]> {
    return Promise.all([this.get(GET_GROUPS)]).then((items) => {
      return new Promise<IProductGroup[]>((resolve) => {
        return resolve(items[0]);
      });
    });
  }

  public getGroup(groupName: string): Promise<IProductGroup> {
    return Promise.all([this.get(GET_GROUPS + "/" + groupName)]).then(
      (items) => {
        return new Promise<IProductGroup>((resolve) => {
          resolve(items[0]);
        });
      }
    );
  }

  public getProduct(product: string): Promise<IProduct> {
    return Promise.all([this.get(GET_PRODUCTS + "/" + product)]).then(
      (items) => {
        return new Promise<IProduct>((resolve) => {
          resolve(items[0]);
        });
      }
    );
  }

  public putGroup(group: IProductGroup): Promise<string> {
    return Promise.all([
      this.putData(GET_GROUPS, null, {
        id: group.id,
        name: group.name,
        customers: group.customers,
      }),
    ]).then(
      (items) => {
        return new Promise<string>((resolve) => {
          return resolve(items[0]);
        })
      }
    );
  }

  public deleteGroup(group: IProductGroup): Promise<string> {
    return Promise.all([
      this.deleteData(GET_GROUPS, null, {
        id: group.id,
        name: group.name,
        customers: group.customers,
      }),
    ]).then(
      (items) =>
        new Promise<string>((resolve) => {
          return resolve(items[0]);
        })
    );
  }

  public putProduct(product: IProduct): Promise<string> {
    return Promise.all([
      this.putData(GET_PRODUCTS, null, {
        id: product.id,
        name: product.name,
        customers: product.customers,
        customerGroups: product.customerGroups,
      }),
    ]).then(
      (items) => {
        return new Promise<string>((resolve) => {
          return resolve(items[0]);
        })
      }
        
    );
  }
}
