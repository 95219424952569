import * as React from 'react';
import { Column } from 'react-table';
import { ColumnTheme, setStyles } from "../data-table/utils/columnStyles";

export const ExpandColumn = (theme?: ColumnTheme): Column => {
    return {
        expander: true,
        Expander: ({isExpanded}: any) => {
            const arrow = isExpanded ? "fa fa-angle-down" : "fa fa-angle-right";
            return (
                <span style={{verticalAlign: "-5px"}}>
                        <i className={arrow} />
                    </span>
            );
        },
        style: {
            cursor: "pointer",
            fontSize: 16,
            padding: "0",
            textAlign: "center",
            userSelect: "none",
            justifyContent: "center",
            ...setStyles(theme),
        },
    };
};