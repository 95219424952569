import {bindActionCreators, compose} from "redux";
import {match, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as React from "react";
import Notifications from "react-notify-toast";

import {IRootReducer} from "../../store/rootReducer";
import * as configurationActions from "src/configuration/store/Customer/Actions";
import * as groupActions from "src/configuration/store/CustomerGroup/Actions";

import {LoadingAnimation, PageHeader} from "src/common";

import CustomerSelector from "../components/CustomerSelector";
import {DropDownFormGroup, IDropDownItem} from "../../common";
import CapabilitiesList from "../components/CapabilitiesList";
import ProcessConfiguration from "../components/ProcessConfiguration";
import {ICustomerProcessDefinition} from "../models/ICustomerProcessDefinition";
import {filterCapabilities} from "../helpers";
import {ICustomerConfigurationState} from "../store/Customer"
import { ICapability, ICustomer, IProcessDefinition } from "src/api/models";
import {Button, ButtonType} from "src/common/button";
import {PERMISSIONS_CONFIGURE_GROUPS} from "../../common/UserPermissions";

interface IMatchParams {
    id: string;
}

interface ICustomerInGroupDetailsProps {
    actions: typeof configurationActions;
    groupActions: typeof groupActions;
    capabilities: ICapability[];
    customerGroups: IDropDownItem[];
    customer: ICustomer;
    processDefinition: IProcessDefinition;
    isLoading: boolean;
    match: match<IMatchParams>;
    hasEditPermission: boolean;
}

interface ICustomerInGroupDetailsState {
    selectedCustomerGroup: string;
}

class CustomerInGroupDetails extends React.Component<ICustomerInGroupDetailsProps, ICustomerInGroupDetailsState> {
    constructor(props: ICustomerInGroupDetailsProps) {
        super(props);
        this.state = {
            selectedCustomerGroup: ""
        }
        this.onCustomerGroupSelect = this.onCustomerGroupSelect.bind(this);
        this.onProcessClick = this.onProcessClick.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.onApplySelectedGroup = this.onApplySelectedGroup.bind(this);
        this.onSyncSelectedGroup = this.onSyncSelectedGroup.bind(this);
    }

    public render() {
        return (
            <>
                <PageHeader title={this.props.match.params.id}/>
                <div className="page-container">
                    <div className="grid-wrapper">
                        <Notifications/>
                        {this.props.isLoading && <LoadingAnimation/>}
                        <CustomerSelector />
                        <DropDownFormGroup
                            name="customer-group"
                            labelText="Customer Group"
                            formGroupClassName="col-25"
                            elements={this.props.customerGroups}
                            onChange={this.onCustomerGroupSelect}
                            value={this.props.customer.customerGroup}
                        />
                        <div className="grid-wrapper">
                            <div className="col-25">
                                <div className="form-group">
                                    <label>Available actions</label>
                                    <div className="formControl">
                                        <Button buttonType={ButtonType.Blue} isDisabled={!this.props.hasEditPermission} onClick={this.onApplySelectedGroup}>
                                            Apply selected group
                                        </Button>&nbsp;
                                        <Button buttonType={ButtonType.Blue} isDisabled={!this.props.hasEditPermission} onClick={this.onSyncSelectedGroup}>
                                            Sync with selected group
                                        </Button>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <b>Apply action</b> will add customer to selected group.<br/>
                                        <b>Sync action</b> will set customer process and step lists to selected group process and step list.
                                    </label>
                                </div>
                            </div>
                        </div>                    
                        <div className="col-30">
                            <CapabilitiesList
                                owner={this.props.customer}
                                onProcessClicked={this.onProcessClick}
                                capabilities={this.filterCapabilities()}/>
                        </div>
                        <div className="col-70">
                            <ProcessConfiguration
                                onSaveChanges={this.onSaveChanges}
                                owner={this.props.customer}
                                processDefinition={this.props.processDefinition}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    public componentDidMount() {
        this.props.actions.loadCapabilities();
        this.props.groupActions.loadCustomerGroups();
    }

    private onCustomerGroupSelect(e: any) {
        const key = e.currentTarget.value;

        this.setState({
            selectedCustomerGroup: key
        });
    }

    private onProcessClick(processName: string): void {

        this.props.actions.loadProcessDefinition(processName, this.props.customer.name);
    }

    private onApplySelectedGroup(): void {
        this.props.groupActions.addCustomerToGroup(this.state.selectedCustomerGroup, this.props.customer.beCode);
    }

    private onSyncSelectedGroup(): void {
        const customerGroup = this.state.selectedCustomerGroup !== "" 
            ? this.state.selectedCustomerGroup 
            : this.props.customer.customerGroup;

        this.props.groupActions.synchronizeCustomerWithGroup(customerGroup, this.props.customer.beCode);
    }

    private filterCapabilities(): ICapability[] {
        const customer = this.props.customer;
        if (customer == null || customer.processIds == null) {
            return [];
        }
        return filterCapabilities(this.props.capabilities, this.props?.customer.processIds);
    }

    private onSaveChanges(processDefinition: ICustomerProcessDefinition) {
        if (this.props.customer == null) {
            return;
        }
        const customer = this.props.customer as ICustomer;

        const processIds = customer.processIds?.map(process => {
            if (processDefinition?.name === process.id) {
                return {...process, enabled: processDefinition.enabled}
            } else {
                return process
            }
        })

        const steps = customer.steps?.map(step => {
            const processStep = processDefinition?.steps.find((s: any) => s.messageType === step.id)
            if (processStep !== undefined) {
                return {...step, enabled: processStep.enabled}
            } else {
                return step
            }
        })
        if (!processIds || !steps) {
            return;
        }

        this.props.actions.saveCustomer({...customer, processIds, steps})

    }
}


const mapStateToProps = (state: IRootReducer) => {
    const localState: ICustomerConfigurationState = state.configurationView;
    const props: Partial<ICustomerInGroupDetailsProps> = {
        isLoading: localState.isLoadingData,
        capabilities: localState.capabilities ?? [],
        customer: localState.customer ?? { customerGroup: "" },
        processDefinition: localState.processDefinition,
        hasEditPermission: state.app.userPermissions.businessFunctions.indexOf(PERMISSIONS_CONFIGURE_GROUPS) !== -1,
        customerGroups: state.groupConfigurationView.customerGroups ?? []
    }

    return props;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(configurationActions, dispatch),
        groupActions: bindActionCreators(groupActions, dispatch)
    }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CustomerInGroupDetails);