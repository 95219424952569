import {Strip} from "../../index";
import * as React from "react";
import {IMonitorListEntry, IMonitorListEntryProps} from "../model/monitor";

export abstract class MonitorListItem<T extends IMonitorListEntry> extends React.Component<IMonitorListEntryProps<T>> {

    protected constructor(props: IMonitorListEntryProps<T>) {
        super(props);
    }

    public render() {
        return (
            <Strip
                key={this.props.key}
                onSelect={this.onSelect.bind(this, this.props.entry.Id)}>
                {this.renderHeader()}
                {this.renderBody()}
            </Strip>
        )
    }
    protected abstract renderHeader() :JSX.Element;
    protected abstract renderBody(): JSX.Element;

    private onSelect(id: string): void {
        this.props.onEntrySelect(id);
    }
}