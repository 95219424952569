import * as React from 'react';
import { IProcess } from './OperationalApi';
import { StripsContainer, Strip, StripHeader, StripBody, CheckboxWithLabel } from '../common';
import { ResultItem } from '../common/ResultItem';
import { StatusItem } from './StatusItem';
import * as moment from 'moment';
import '../common/styles/resultSection.css';

interface IProcessesResultProps {
    processes: IProcess[];
    onProcessSelect: (id: string) => void;
    onProcessPayloadsSelect: (id: string) => void;
}

export class ProcessesResult extends React.Component<IProcessesResultProps> {
    constructor(props: IProcessesResultProps) {
        super(props);
    }

    public render() {
        const dateTimeFormat: string = 'DD-MM-YYYY HH:mm:ss';
        const processesNotEmpty: boolean = this.props.processes && this.props.processes.length > 0;
        const resultItemClassName = 'col-25';
        return ( 
            <div>
            { processesNotEmpty && 
                <StripsContainer>
                    {
                        this.props.processes ? (
                            this.props.processes.map((p: any, index) => 
                                <Strip key={p.CorrelataionId + '_' + index}>
                                    <StripHeader onSelect={this.onProcessSelect.bind(this, p.Id)}>
                                        <div className="result-header-bar">
                                            <ResultItem title="Correlation Id" value={p.CorrelataionId} className="" clipboardCopy={true} />
                                            <StatusItem status={p.ProcessStatusName} />
                                        </div>
                                    </StripHeader>
                                    <StripBody onSelect={this.onProcessSelect.bind(this, p.Id)}>
                                        <div className="grid-wrapper">
                                            <ResultItem title="Transaction Id" value={p.TransactionId} className={resultItemClassName} clipboardCopy={true} />
                                            <ResultItem title="Capability" value={p.Capability} className={resultItemClassName} />
                                            <ResultItem title="System" value={p.SystemName} className={resultItemClassName} />
                                            <ResultItem title="Process name" value={p.ProcessName} className={resultItemClassName} />
                                        </div>
                                        <div className="grid-wrapper">
                                            <ResultItem title="Unique Id" value={p.UniqueId} className={resultItemClassName} clipboardCopy={true} />   
                                            <ResultItem title="Created" value={p.CreatedDate ? moment(p.CreatedDate).format(dateTimeFormat) : ''} className={resultItemClassName} />
                                            <ResultItem title="Customer" value={p.CustomerName} className={resultItemClassName} />
                                        </div>
                                    </StripBody>
                                    <div>
                                        <CheckboxWithLabel
                                            name={"includepayloads"}
                                            labelText="Include Payloads" 
                                            placeholder="Include Payloads"
                                            onChange={this.onChangeIncludePayloads.bind(this, p.Id)}
                                            value={"true"}
                                        />
                                    </div>
                                </Strip>
                            )
                        ) :
                        (
                            <div />
                        )
                    }
                </StripsContainer>
            }
            </div>
        );
    }

    private onProcessSelect(transactionId: string, e: any): void {
        this.props.onProcessSelect(transactionId);
    }

    private onChangeIncludePayloads(transactionId: string, e: any): void {
        this.props.onProcessPayloadsSelect(transactionId);
    }
}