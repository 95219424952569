import * as actionTypes from "./GroupsActionTypes";
import { IGroupsContainer } from "./GroupsContainer";

export const groupsViewReducer = (
  state: IGroupsContainer,
  action: any
): IGroupsContainer => {
  switch (action.type) {
    case actionTypes.DATA_LOAD_IN_PROGRESS:
      return { ...state, isLoadingData: true };

    case actionTypes.LOAD_AVAILABLE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isLoadingData: false,
        availableCustomers: action.availableCustomers,
      };

    case actionTypes.LOAD_AVAILABLE_GROUPS_SUCCESS:
      return {
        ...state,
        isLoadingData: false,
        availableGroups: action.availableGroups,
      };

    case actionTypes.SAVE_IN_PROGRESS:
      return { ...state, isLoadingData: true };

    case actionTypes.LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingData: false,
        products: action.products,
      };

    case actionTypes.LOAD_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingData: false,
        product: action.product,
      };

    case actionTypes.SAVE_PRODUCT_SUCCESS:
      return { ...state, isLoadingData: false };

    case actionTypes.SAVE_PRODUCT_ERROR:
      return { ...state, isLoadingData: false };

    case actionTypes.GROUP_SAVE_IN_PROGRESS:
      return { ...state, isLoadingData: true };

    case actionTypes.GROUP_SAVE_ERROR:
      return { ...state, isLoadingData: false };
  }

  return { ...state };
};
