import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { HorizontalBar } from 'react-chartjs-2';
import { DatePickerSection } from "../common/DatePickerSection";
import { ICapabilityChartsProps, IStateProps, ICapabilityProps } from "./dashboardModel";
import { DoughnutLinks } from './DoughnutLinks';

export class ChartsSection extends React.Component<ICapabilityChartsProps, any> {
    constructor(props: ICapabilityChartsProps){
        super(props);
        this.IsShowCapabilityBar = this.IsShowCapabilityBar.bind(this);
        this.GetStatesLabels = this.GetStatesLabels.bind(this);
        this.GetDataCounts = this.GetDataCounts.bind(this);
        this.CreateDoughnut = this.CreateDoughnut.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onElementsClick = this.onElementsClick.bind(this);
    }

    public render() {
        const doughnuts: any = [];
        if(!this.IsShowCapabilityBar()){
            doughnuts.push(this.CreateDoughnut("ALL CAPABILITY OVERVIEW", this.props.allStates, false));
        }
        else{
            doughnuts.push(this.CreateDoughnut(this.props.capabilities[0].name, this.props.allStates, true));
        }
        const doughnutCharts = doughnuts.map((doughnut: any) => {
            return (
                <div className="col-md-auto" key={doughnut.name}>
                    <DoughnutLinks name={doughnut.name} isLinked={doughnut.isLinked} />
                    <div className="chart-container" key={doughnut.name}>
                        <Doughnut data={doughnut} />
                    </div>
                </div>
            );
        });
        const horizontalChar = () => {
            let title = "TOP 5 MOST FAILURED CAPABILITIES:";
            if(this.IsShowCapabilityBar()){
                title = "FAILURED PROCESSES"
                if(this.props.selectedCapability){
                    title = title + " IN " + this.props.selectedCapability
                }
            }
            return (
                <div className="col-md-auto">
                    <h2>{title}</h2>
                    <div className="chart-main-container">
                        <HorizontalBar data={this.CreateHorizontalChar(this.props.capabilities)} onElementsClick={this.onElementsClick} />
                    </div>
                </div>
            );
        }

        return (
            <section className="page-container">
                <div className="grid-wrapper">
                    <div className="row-dashboard">
                        <div className="row">
                            <h2>Period:</h2>
                            <div className="col-4">
                                <DatePickerSection id="dashboard-from-date" name="from-date" placeholderText="from date" popperPlacement="left-start" numberOfDays={-14} selectedDate={this.props.dateFrom} onDateSelect={this.onChange} />
                            </div>
                            <div className="col-4">
                                <DatePickerSection id="dashboard-to-date" name="to-date" placeholderText="to date" popperPlacement="left-start" numberOfDays={-14} selectedDate={this.props.dateTo} onDateSelect={this.onChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row-dashboard">
                            { horizontalChar() }
                            { doughnutCharts }
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    private onElementsClick(e: any){
        if (this.props.onCharElementClick) {
            this.props.onCharElementClick(e);
        }
    }

    private onChange(e: any) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    private IsShowCapabilityBar(): boolean{
        return this.props.allStates !== null && this.props.isShowCapabilityBar
    }

    private GetCapabilityFailedState(capabilities?: ICapabilityProps[]): number[]{
        const counters: number[] = [];
        if(capabilities){
            for (const capability of capabilities) {
                if(capability.states){
                    for (const state of capability.states) {
                        counters.push(state.count)
                    }
                }
            }
        }
        return counters;
    }

    private GetProcessesFailedState(capability: ICapabilityProps): number[]{
        const counters: number[] = [];
        if(capability.processes){
            for (const process of capability.processes) {
                if(process.states){
                    for (const state of process.states) {
                        counters.push(state.count)
                    }
                }
            }
        }
        return counters;
    }

    private GetStatesLabels(states?: IStateProps[]): string[]{
        if(states != null){
            return states.map(({name}) => name);
        }else{
            return [];
        }
    }

    private GetDataCounts(states?: IStateProps[]): number[]{
        if(states != null){
            return states.map(({count}) => count);
        }else{
            return [];
        }
    }

    private CreateDoughnut(capabilityName: string, states: IStateProps[], isLinked: boolean): any{
        const processLabels: string[] = this.GetStatesLabels(states);
        const dataCounts: number[] = this.GetDataCounts(states);
        const doughnut = {
            name: capabilityName,
            labels: processLabels,
            isLinked,
            datasets: [{
                label: '# of Votes',
                data: dataCounts,
                backgroundColor: [
                    'rgba(151, 197, 80, 1)',
                    'rgba(57, 143, 209, 1)',
                    'rgba(222, 69, 97, 1)'
                ],
                borderColor: [
                    'rgba(151,197,80,1)',
                    'rgba(57, 143, 209, 1)',
                    'rgba(222, 69, 97, 1)'
                ],
                borderWidth: 1
            }]
        }
        return doughnut;
    }

    private GetCapabilitiesLabels(capabilities: ICapabilityProps[]): string[]{
        if(capabilities.length > 0){
            return capabilities.map(({name}) => name);
        }else{
            return [];
        }
    }

    private GetProcessesLabels(capability: ICapabilityProps): string[]{
        if(capability.processes != null){
            return capability.processes.map(({name}) => name);
        }else{
            return [];
        }
    }

    private CreateHorizontalChar(items: ICapabilityProps[]){
        let labels: string[] = [];
        let data: number[] = [];
        if(items){
            if(items.length === 1){
                labels = this.GetProcessesLabels(items[0])
                data = this.GetProcessesFailedState(items[0])
            }
            else{
                labels = this.GetCapabilitiesLabels(items)
                data = this.GetCapabilityFailedState(items)
            }
        }
        return {
            labels,
            datasets: [
                {
                    label: 'Failed processes',
                    backgroundColor: 'rgba(222,69,97,1)',
                    borderColor: 'rgba(222,69,97,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(222,69,97,1)',
                    hoverBorderColor: 'rgba(222,69,97,1)',
                    data
                }]
        }
    }
}