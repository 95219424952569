import * as React from "react"

export const RolesList = (props: any) => {
	return (
		<ul className="nopadding">
			{props.roles.map((role: string) => (
				<li key={role} className="nopadding small-inactive-text">
					{role}
				</li>
			))}
		</ul>
	)
}
