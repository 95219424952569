import * as Msal from 'msal';
import * as React from 'react';
import {IAppContext, IAuthError, IConfig} from './AuthenticationModel';
import {AdvancedError} from '../error/AdvancedError';
import {SUPPORTED_ERROR_TYPES, UNKNOWN_ERROR} from '../error/CustomErrorTypes';
import {BRAIN_ERROR, BRAIN_ERROR_JSON} from 'src/error/ErrorConstants';

const msalLogger = new Msal.Logger(loggerCallback, { level: Msal.LogLevel.Warning });
const state : IAppContext = {
  stopLoopingRedirect: false,
  scopes: []
}

function parseAuthError(authError:  Msal.AuthError): IAuthError {
  for(const errorType of SUPPORTED_ERROR_TYPES){
      if(authError.errorMessage.includes(errorType.errorCode + ":")) {
          return errorType;
      }
  }
  return UNKNOWN_ERROR;
}

function loggerCallback(logLevel: any, message: string, piiLoggingEnabled: any) {
  console.log(message);
}

function authCallback(exception: Msal.AuthError, response: Msal.AuthResponse) {
    if(exception ) {
      if(exception.errorCode === "login_progress_error") {
        sessionStorage.clear();
        return;
      }
      state.stopLoopingRedirect = true;
      const errorType = parseAuthError(exception);
      new AdvancedError( errorType.message, errorType.name, errorType.errorCode, exception.stack).throwAuthError();
    }
}

function acquireToken(successCallback: ()=> void) {
  const localMsalApp = window.msal as Msal.UserAgentApplication;
  const user = localMsalApp.getAccount();

  const accessTokenRequest: Msal.AuthenticationParameters = { scopes: state.scopes };
    if (!user) {
      localMsalApp.loginRedirect(accessTokenRequest);
    }
    else {
      localMsalApp.acquireTokenSilent(accessTokenRequest)    
      .then(accessTokenResponse => {
        state.accessToken = accessTokenResponse.accessToken;      
        if(state.launchApp) {
          state.launchApp();
          if (successCallback) {
            successCallback();
          }
        }
      })
      .catch(error => {
        if (error) {
          localMsalApp.acquireTokenRedirect(accessTokenRequest);
        }
      });
    }
}

const authentication = {
  initialize: (config: IConfig) : Msal.UserAgentApplication => {

    const authorityValue =  `https://login.microsoftonline.com/${config.tenant}`;
    const scopes = config.scopes;
    if (!scopes || scopes.length === 0) {
      state.stopLoopingRedirect = true;
    }
     state.scopes = scopes;

    const msalConfig : Msal.Configuration = {
      auth: {
        clientId: config.applicationId,
        authority: authorityValue,
        validateAuthority: true,
        navigateToLoginRequestUrl: true,
        redirectUri: config.redirectUri,
        postLogoutRedirectUri: config.postLogoutRedirectUri
      },
      cache: {
        cacheLocation: config.cacheLocation as Msal.CacheLocation,
        storeAuthStateInCookie: true
      },
      system: {
        logger: msalLogger
      }
    };

    const msalUserAgentApp = new Msal.UserAgentApplication(msalConfig);
    
    if (!msalUserAgentApp.isCallback(window.location.hash) && window.parent === window && !window.opener){
      msalUserAgentApp.handleRedirectCallback(authCallback);
    }
    
    return msalUserAgentApp;
  },

  run: (launchApp: any, onAuthFailed: any) => {    
    if(!authentication.isSuccess()) {
      onAuthFailed();
      return;
    }

    state.launchApp = launchApp;
    const msal = window.msal as Msal.UserAgentApplication;
    if (!msal.isCallback(window.location.hash) && window.parent === window && !window.opener){
      if (!state.stopLoopingRedirect) {
        acquireToken(() => undefined);
      }
    }    
  },

  required: (WrappedComponent: any, renderLoading: any) =>  {
    return class extends React.Component<any, any> {
      constructor(props: any) {
        super(props);
        this.state = {
          signedIn: false,
          error: null,
        };
      }

      public componentWillMount() {        
        acquireToken(() => {
          this.setState({
            signedIn: true
          });
        });
      }

      public render() {
        if (this.state.signedIn) {
          return (<WrappedComponent {...this.props} />);
        }
        return typeof renderLoading === 'function' ? renderLoading() : null;
      }
    };
  },
  signOut: () => {
    const localMsalApp = window.msal as Msal.UserAgentApplication;
    localMsalApp.logout()
  },  
  getUser: () : Msal.Account => {
    const localMsalApp = window.msal as Msal.UserAgentApplication;    
    return localMsalApp.getAccount();
  },
  isSuccess: (): boolean => sessionStorage.getItem(BRAIN_ERROR.KEY) !== BRAIN_ERROR.OCCURED,

  getOccuredError: (): AdvancedError | null => {
    return sessionStorage.getItem(BRAIN_ERROR_JSON.KEY) ?
        JSON.parse(sessionStorage.getItem(BRAIN_ERROR_JSON.KEY) as string) : null;
  },

  clearErrors: () => {
    sessionStorage.removeItem(BRAIN_ERROR.KEY);
    sessionStorage.removeItem(BRAIN_ERROR_JSON.KEY);
  },
  
  getAccessToken: () => state.accessToken
}

export default authentication;