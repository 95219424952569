import * as React from "react";
import {MonitorList} from "../../common/monitor/components/MonitorList";
import {RejectedMessagesListItem} from "./RejectedMessagesListItem";
import {IRejectedMessageEntry} from "../model/RejectedMessagesModel";
import {IMonitorListEntry} from "../../common/monitor/model/monitor";


export class RejectedMessagesList extends MonitorList {
    private static getKey(entry: IMonitorListEntry, index: number): string {
        return entry.Id + '_' + index;
    }

    protected mapEntry(e: Readonly<IMonitorListEntry>, index: number): JSX.Element | undefined {
            return (
                <RejectedMessagesListItem key={RejectedMessagesList.getKey(e, index)}
                                          entry={e as IRejectedMessageEntry}
                                          onEntrySelect={this.props.onEntrySelect}/>
        )
    }

}