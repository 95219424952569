import * as React from 'react';
import Tree from 'react-d3-tree';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as processDetailsActions from './processDetailsActions';
import { PageHeader } from '../../common/PageHeader';
import { LoadingAnimation } from '../../common/LoadingAnimation';
import { IProcessStepItem } from './processDetailsModel';
import { IRootReducer } from '../../store/rootReducer';
import { StepDetailsSection } from './StepDetailsSection';
import { PERMISSIONS_RESEND_MESSAGE } from 'src/common/UserPermissions';
import '../../common/styles/ResultItem.css';

export interface IProcessDetailsContainerState {
    step: IProcessStepItem;
    isShowDetails: boolean;
    isShowResendConfirmation: boolean;
}

class ProcessDetailsContainer extends React.Component<any, IProcessDetailsContainerState> {
    constructor(props: any) {
        super(props);

        this.state = {
            step: {
                id: '',
                messageType: '',
                sessionId: '',
                stepState: '',
                sessionFinalizer: '',
                stepInstanceFailures: [],
                stepInstanceSystems: [],
                fileReferences: []
            },
            isShowDetails: false,
            isShowResendConfirmation: false
        }

        this.selectStepForResend = this.selectStepForResend.bind(this);
        this.resendEnd = this.resendEnd.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.downloadAllFiles = this.downloadAllFiles.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.resendProcess = this.resendProcess.bind(this);
        this.resendStep = this.resendStep.bind(this);
    }

    public componentDidMount() {
        this.props.actions.loadItem(this.props.match.params.id)
    }

    public render() {
        const completedLabelStyle = {
            color: 'white',
        };
        const failedLabelStyle = {
            color: 'white',
            textDecoration: 'underline'
        };
        const whiteColor = {
            color: 'white'
        };
        const modalStyle = {
            display: this.props.showResendUserConfirmationDialog ? 'block' : 'none'
        };
        const translate = {
            x: 250,
            y: 150
        };
        const nodeSvgShape = {
            shape: 'rect',
            shapeProps: {
                width: 30,
                height: 30,
                x: -10,
                y: -10,
            }
        };
        const textLayout =
        {
            textAnchor: "middle",
            x: 0,
            y: -70,
            transform: "scale(0.7 0.7)"
        };

        const separation = { siblings: 2, nonSiblings: 2 };

        const isLoading = this.props.loading;
        const canShowResendButton = this.hasPermissions(PERMISSIONS_RESEND_MESSAGE) && (this.props.item.processState === 'Completed' || this.props.item.processState === 'Failed');
        return (
            <div>
                { isLoading && <LoadingAnimation />}
                <div>
                    <PageHeader title={this.props.item.name} />
                    <div className="modal full-width single-row" style={modalStyle}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <strong className="modal-title">Warning</strong>
                                </div>
                                <div className="modal-body double-modal">
                                    Are you sure you want to resend the step?
                            </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.props.actions.resendCancel} className="button-large button-transparent">No</button>
                                    <button type="button" onClick={this.resendEnd} className="button-large button-blue">Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="page-container">
                        {
                            this.state.isShowResendConfirmation ?
                                (
                                    <div className="grid-wrapper">
                                        <div className="col-100">
                                            <div className="message success"><i aria-hidden="true" className="fa fa-check-circle fa-3x" /><span id="message_success">Selected step was successfully resended.</span><i id="close_success" className="fa fa-close" /></div>
                                        </div>
                                    </div>
                                ) :
                                (
                                    ""
                                )
                        }
                        <div className="grid-wrapper">
                            <div className="col-50">
                                <form className={"filter-search-criteria"}>
                                    <fieldset>
                                        <div className="filter-header">
                                            <h3>Detailed Information <i className="fa fa-refresh fa-lg" style={{ cursor: "pointer", marginLeft: "7px" }} onClick={this.refreshPage} /></h3>
                                        </div>
                                        <div>
                                            <div className={"grid-wrapper filter-body"}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Process</td>
                                                            <td><span className="regular-link-text">{this.props.item.name}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Correlation ID</td>
                                                            <td><span className="regular-link-text result-item">{this.props.item.originalCorrelationId}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Unique ID</td>
                                                            <td><span className="regular-link-text result-item">{this.props.item.uniqueId}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Transaction ID</td>
                                                            <td><span className="regular-body-text result-item">{this.props.item.correlationId}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>State</td>
                                                            <td>
                                                                {this.props.item.processState === 'New' ? (<span>{this.props.item.processState}</span>) : ('')}
                                                                {this.props.item.processState === 'Completed' ? (<code className="green" style={completedLabelStyle}>{this.props.item.processState}</code>) : ('')}
                                                                {this.props.item.processState === 'Running' ? (<span>{this.props.item.processState}</span>) : ('')}
                                                                {this.props.item.processState === 'Failed' ? (<code className="red" style={whiteColor}>{this.props.item.processState}</code>) : ('')}
                                                            &nbsp;
                                                            {canShowResendButton ? (<button className="button button-blue" type="button" onClick={this.resendProcess} >Resend</button>) : ('')}

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Customer</td>
                                                            <td><span className="regular-body-text">{this.props.item.customer}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Payloads
                                                            </td>
                                                            <td>
                                                            <a href={"#"} onClick={this.downloadAllFiles} data-process-instance-id={this.props.item.id}>Download All</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="filter-header">
                                            <h3>Steps History</h3>
                                        </div>
                                        <div>
                                            <div className={"grid-wrapper filter-body"}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td><b>Message Type</b></td>
                                                            <td><b>Session Id</b></td>
                                                            <td><b>State</b></td>
                                                            <td>{canShowResendButton && this.props.item.processState === 'Failed' ? <b>Resend</b> : null}</td>
                                                        </tr>
                                                        {
                                                            this.props.item.steps.map((step: any) => {
                                                                return (
                                                                    <tr key={step.messageType}>
                                                                        <td>{step.messageType}</td>
                                                                        <td>
                                                                            {!step.sessionId || 0 === step.sessionId ? (<span className="regular-inactive-text"><i>Empty</i></span>) : step.sessionId}
                                                                        </td>
                                                                        <td>
                                                                            {step.stepState === 'New' ? (<span>{step.stepState}</span>) : ('')}
                                                                            {step.stepState === 'Completed' ? (<code className="green" style={completedLabelStyle}><a href={"#"} onClick={this.selectStepForResend} data-messagetype={step.messageType} style={failedLabelStyle}>{step.stepState}</a></code>) : ('')}
                                                                            {step.stepState === 'Running' ? (<span>{step.stepState}</span>) : ('')}
                                                                            {step.stepState === 'Failed' ? (<code className="red"><a href={"#"} onClick={this.selectStepForResend} data-messagetype={step.messageType} style={failedLabelStyle}>{step.stepState}</a></code>) : ('')}
                                                                        </td>
                                                                        <td>
                                                                            {canShowResendButton && step.stepState === 'Failed' ? (
                                                                                <button className="button button-blue" type="button" onClick={this.resendStep.bind(this, step)} >Resend</button>) : null}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                                <div>
                                    {
                                        this.state.isShowDetails ?
                                            (
                                                <StepDetailsSection
                                                    item={this.props.item}
                                                    step={this.state.step}
                                                    processState={this.props.item.processState}
                                                    downloadFile={this.downloadFile}
                                                    resendStart={this.props.actions.resendStart}
                                                    hideResendPanel={this.props.actions.hideResendPanel}
                                                    userPermissions={this.props.userPermissions} />
                                            ) :
                                            (
                                                <span />
                                            )
                                    }
                                </div>
                            </div>
                            <div className="col-50">
                                <form className={"filter-search-criteria"}>
                                    <fieldset>
                                        <div className="filter-header">
                                            <h3>{this.props.item.name}</h3>
                                        </div>
                                        <div>
                                            <div id="treeWrapper" style={{ width: '100%', height: '600px' }}>
                                                <Tree
                                                    data={this.props.item.stepNodes}
                                                    orientation={"vertical"}
                                                    pathFunc={"straight"}
                                                    translate={translate}
                                                    collapsible={false}
                                                    nodeSvgShape={nodeSvgShape}
                                                    transitionDuration={0}
                                                    separation={separation}
                                                    textLayout={textLayout} />
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

    private selectStepForResend(e: any) {
        const messageType = e.currentTarget.getAttribute('data-messagetype');
        let steps: IProcessStepItem[];
        steps = this.props.item.steps;
        const step = steps.filter(data => data.messageType === messageType)[0];

        this.setState({
            step,
            isShowDetails: true
        });

        this.props.actions.showResendPanel();
    }

    private refreshPage() {
        this.props.actions.loadItem(this.props.match.params.id)
    }

    private resendProcess() {
        if (confirm('Do you really want to resend this whole process?')) {
            this.props.actions.resendProcess(this.props.item.steps[0].id)
        }
    }

    private resendStep(step: any) {
        if (confirm(`Do you really want to resend "${step.messageType}"?`)) {
            if (step.stepInstanceSystems) {
                this.props.actions.resendStepInstance(step.stepInstanceSystems[0].id)
            }
        }
    }

    private downloadFile(e: any) {
        const url = e.currentTarget.getAttribute('data-url');
        const id = e.currentTarget.getAttribute('data-step-instance-id');
        const name = e.currentTarget.getAttribute('data-name');
        const system = e.currentTarget.getAttribute('data-system');

        this.props.actions.downloadFile(url, id, name, system);
    }

    private downloadAllFiles(e: any) {
        const id = e.currentTarget.getAttribute('data-process-instance-id');
        this.props.actions.downloadAllFiles(id);
    }

    private resendEnd(e: any) {
        this.setState({
            isShowResendConfirmation: true
        });
        this.props.actions.resendEnd(this.state.step.id);
    }

    private hasPermissions(permission: string): boolean {
        return this.props.userPermissions.businessFunctions.indexOf(permission) !== -1
    }
}

const mapStateToProps = (state: IRootReducer, ownProps: any) => {
    return {
        loading: state.processDetailsView.loading,
        errorMessage: state.processDetailsView.errorMessage,
        item: state.processDetailsView.item,
        showResendPanel: state.processDetailsView.showResendPanel,
        showResendUserConfirmationDialog: state.processDetailsView.showResendUserConfirmationDialog,
        showResendConfirmation: state.processDetailsView.showResendConfirmation,
        userPermissions: state.app.userPermissions,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(processDetailsActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessDetailsContainer);