import * as React from "react";
import { ICapability, ICustomer, ICustomerGroup } from "src/api/models";
import Accordion from "src/common/Accordion";
import ProcessStatusIndicator from "./ProcessStatusIndicator";



/** @type {{search: React.CSSProperties}} */
const processItemStyle = {
    cursor: 'pointer'
} as React.CSSProperties;


interface ICapabilitiesListProps {
    capabilities: ICapability[] | null;
    onProcessClicked: (processName: string) => void;
    owner: ICustomerGroup | ICustomer;
}

export default class CapabilitiesList extends React.Component<ICapabilitiesListProps> {

    constructor(props: ICapabilitiesListProps) {
        super(props);
        this.handleProcessClicked = this.handleProcessClicked.bind(this);
    }

    // region: Render functions
    public render() {
        let content = <p>No capabilities found for selected customer group</p>;
        if (this.hasCapabilitiesSet()) {
            content = this.getContent();
        }
        return content;
    }

    private getProcessElement(process: string): JSX.Element {
        return (
            <li style={processItemStyle} key={process} onClick={this.handleProcessClicked} data-process={process}>
                <ProcessStatusIndicator process={process} ownerProcesses={this.props.owner.processIds ?? []} /> {process}
            </li>
        );
    }

    private getCapabilityElement(capability: ICapability): JSX.Element {
        const processElements = capability.processes.map(
            (process: string) => this.getProcessElement(process)
        );

        return (
            <Accordion title={capability.name} key={capability.name} isExpanded={true}>
                <ul>
                    {processElements}
                </ul>
            </Accordion>
        );
    }

    private getContent(): JSX.Element {
        const elements = this.props.capabilities?.map(
            (capability: ICapability) => this.getCapabilityElement(capability)
        );
        return <>{elements}</>
    }
    // endregion

    private handleProcessClicked(e: any): void {
        const processName = e.currentTarget.getAttribute('data-process');
        this.props.onProcessClicked(processName);
    }

    private hasCapabilitiesSet(): boolean {
        return (this.props.capabilities && this.props.capabilities.length > 0) ?? false;
    }
}