function addStartingSlash(part: string): string {
    if (part.startsWith('/')) {
        return part;
    }

    return '/' + part;
}

function removeEndingSlash(part: string) {
    if (part.endsWith('/')) {
        return part.substr(0, part.length -1);
    }

    return part;
}

export function JoinUrl(...urlParts: string[]): string {
    let url = '';
    if (urlParts && urlParts.length > 0) {
        for (let i=0;i < urlParts.length; i++) {
            if (urlParts[i] && urlParts[i].length > 0) {
                if (i === 0) {
                    url = urlParts[i];
                }
                else {
                    url = removeEndingSlash(url) + addStartingSlash(urlParts[i]);
                }
            }
        }
    }

    return url;
}