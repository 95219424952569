import * as React from "react"
import authentication from "../common/Authentication"
import { IProfileContext } from "./NavLinks"
import { RolesList } from "./RolesList"

export class ProfileSection extends React.Component<IProfileContext, any> {
	constructor(props: IProfileContext) {
		super(props)
	}

	public render() {
		const user = authentication.getUser()
		const styleName = this.props.showProfile ? "block" : "none"
		return (
			<div id="dropdown1" className="dropdown-content info-dropdown" style={{ display: styleName }}>
				<ul className="dropdown-content-area">
					<li className="dropdown-profile-picture">
						<img alt="asd" src="https://design-system.azureedge.net/images/icons/DefaultProfileImg64.png" />
					</li>
					<li className="dropdown-details">
						<h5>
							{user.name}
							<br />
							<small>{user.idToken.email}</small>
						</h5>
						<RolesList className="margin-bottom" roles={this.props.userPermissions!.roles} />
					</li>
				</ul>
				<div className="dropdown-button-footer nav-dropdown-footer display-table">
					<button type="button" className="button-large button-transparent" onClick={this.onSignOut}>
						Logout
					</button>
				</div>
			</div>
		)
	}

	private onSignOut = () => {
		authentication.signOut()
	}
}
