import {ApiServiceBase} from "../../../common/ApiServiceBase";
import {IMessageDetailsContainerState, IOriginalPayloadQuery} from "../model/MessageDetailsModel";
import {GET_ORIGINAL_PAYLOAD_URL, GET_REJECTED_MESSAGES_URL} from "../../api/monitorApiService";

export class MessageDetailsApi extends ApiServiceBase {
    private static createEmptyItem(): IMessageDetailsContainerState {
        return {
            originalPayload: '',
            loading: false,
            data: {
                id: '',
                createdDate: '',
                status: '',
                monitorMessage: '',
                correlationId: '',
                messageType: '',
                customer: '',
                uniqueId:'',
                systemName: '',
            }
        };
    }

    private static getMessageDetailsUrl(id: string) {
        return GET_REJECTED_MESSAGES_URL+`/${id}`;
    }

    public getItem(id: string): Promise<IMessageDetailsContainerState> {

        return new Promise((resolve) => {

            this.get(MessageDetailsApi.getMessageDetailsUrl(id))
                .then(responseData => {
                    const item = MessageDetailsApi.createEmptyItem();
                    item.data.monitorMessage = responseData.message;
                    item.data.customer = responseData.customer;
                    item.data.correlationId = responseData.correlationId;
                    item.data.uniqueId = responseData.uniqueId;
                    item.data.systemName = responseData.system;
                    item.data.messageType = responseData.messageType;
                    item.originalPayload = responseData.url;
                    item.data.createdDate = responseData.timestamp;
                    item.data.status = responseData.rejectionTypeDisplay;
                    item.data.id = id;

                    resolve(item);
                });
        });
    }

    public IsJsonString(str: string) {
        if (str === null) {
            return false;
        }
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public downloadFile(query: IOriginalPayloadQuery): Promise<void> {
        const name = query.Path.split('/').slice(-1)[0];
        return new Promise((resolve) => {
            this.postRawData(GET_ORIGINAL_PAYLOAD_URL, query)
                .then((response: Response) => {
                    if (response.ok){
                        response.blob().then(blob =>
                        {
                            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                                // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                                window.navigator.msSaveBlob(blob, name);
                            } else {
                                const URL = window.URL || (window as any).webkitURL;
                                const downloadUrl = URL.createObjectURL(blob);

                                // use HTML5 a[download] attribute to specify filename
                                const a = document.createElement("a");
                                // safari doesn't support this yet
                                if (typeof a.download === 'undefined') {
                                    window.location.href = downloadUrl;
                                } else {
                                    a.href = downloadUrl;
                                    a.download = name;
                                    document.body.appendChild(a);
                                    a.click();
                                    a.remove();
                                }
                                setTimeout(() => { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                            }
                            resolve();
                        });
                    }
                    else {
                        alert('Error downloading file. Status:' + response.status);
                        resolve();
                    }
                });
        });
    }
}