import * as React from 'react';
import { Column } from 'react-table';
import { ColumnTheme, setStyles } from "../data-table/utils/columnStyles";
import { header } from './Header';
import { Link } from 'react-router-dom';

export const LinkColumn = (
    accessor: string,
    name: string,
    config: {
        width?: number,
        theme?: ColumnTheme,
        resizable?: boolean,
    } = {}
): Column => {
    return {
        headerClassName: 'data-table header',
        show: true,
        accessor,
        width: config.width,
        resizable: config.resizable,
        style: {
            cursor: 'pointer',
            ...setStyles(config.theme)
        },
        Header: (rowInfo: any) => header(rowInfo, name),
        Cell: (rowInfo) => (
            <Link to={`/consignee/${rowInfo.value}/be-codes`}>
                {rowInfo.value}
            </Link>
        )
    };
};