import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ErrorContainer from '../error/ErrorContainer';
import authentication from 'src/common/Authentication';
import { BRAIN_ERROR_URL } from 'src/error/ErrorConstants';

export const UnauthorizedRoutingConfiguration = () => {
    return (
        <main className="full-width">                        
            <Switch>
                <Route path="/error" component={ ErrorContainer } /> 
                <Redirect to= { {pathname: BRAIN_ERROR_URL, state: authentication.getOccuredError() } } /> 
            </Switch>
        </main>
    );
};