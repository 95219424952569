import * as React from 'react';

export const conditionComponent = <TProps extends any>(WrappedComponent: React.ComponentType<TProps>) => {
    interface IProps{
        shouldShow: boolean;
    }

    return class extends React.Component<IProps & TProps> {
        public render(){
            return this.props.shouldShow 
                ? <WrappedComponent {...this.props}/>
                : <React.Fragment />
        }
    }
}