import * as React from "react";
import Accordion from "src/common/Accordion";
import ReactTable, { RowInfo } from "react-table";
import { ColumnFactory } from "src/common/data-table/ColumnFactory";
import { IProductGroup } from "./GroupModels";
import { Component } from "react";
import { confirmMessageAlert } from "src/common/messages/confirmAlertMessage";

interface ICustomerGroupTableContainer {
  selectedRowIndex: number;
  groups: IProductGroup[];
  isReadOnly: boolean;
  onRowDeleteClick: (event: any) => any;
  onRowClick: (rowInfo: RowInfo) => void;
  onNewCustomerGroupClick: (event: any) => void;
  onEditCustomerGroupClick: (event: any) => void;
}

interface ICustomerGroupTableContainerState {
  selectedRow: any;
}

export class CustomerGroupTable extends Component<
  ICustomerGroupTableContainer,
  ICustomerGroupTableContainerState
> {
  constructor(props: any) {
    super(props);

    this.state = { selectedRow: { index: -1 } };

    this.handleDeleteRowClick = this.handleDeleteRowClick.bind(this);
    this.handleNewCustomerClick = this.handleNewCustomerClick.bind(this);
    this.handleEditGroupsClick = this.handleEditGroupsClick.bind(this);
  }

  public componentWillReceiveProps(props: any) {
    this.setState({ selectedRow: { index: props.selectedRowIndex } });
    this.forceUpdate();
  }

  public handleDeleteRowClick = (event: any) => (val: any) => {
    confirmMessageAlert(
      "Are you sure?",
      "This group will no longer be available on this product!",
      () => {
        this.props.onRowDeleteClick(event);
      }
    );
  };

  public handleNewCustomerClick = (val: any) => {
    this.props.onNewCustomerGroupClick(val);
  };

  public handleEditGroupsClick = (val: any) => {
    this.props.onEditCustomerGroupClick(val);
  };

  public handleTablePropsChanged = (state: any, rowInfo: RowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e: any) => {
          const target = (e.target as Element).id;
          e.stopPropagation();
          if (!target.includes("dt-buttons")) {
            this.props.onRowClick(rowInfo);
            this.setState({ selectedRow: rowInfo });
          }
        },
        className:
          rowInfo.index === this.state.selectedRow.index
            ? "selected-table-row"
            : "",
      };
    } else {
      return {};
    }
  };

  public render() {
    const columns = [
      ColumnFactory.standardColumn("name", "Group", { width: 150 }),
      ColumnFactory.stringArrayColumn("customers", "Customers"),
    ];

    if (this.props.isReadOnly) {
      columns.push({
        width: 130,
        Cell: (event) => (
          <a id="dt-buttons" onClick={this.handleDeleteRowClick(event)}>
            Remove
          </a>
        ),
      });
    }

    const manageButtons = this.props.isReadOnly ? (
      <>
        <button
          type="button"
          className="button button-blue header-button"
          onClick={this.handleEditGroupsClick}
        >
          Edit
        </button>
        <button
          type="button"
          className="button button-blue header-button new-group-button"
          onClick={this.handleNewCustomerClick}
        >
          Add new
        </button>
      </>
    ) : null;

    return (
      <>
        {
          <Accordion title={"Groups"} key={"groups"} isExpanded={true}>
            {manageButtons}
            <div className="data-table-container modal-data-table">
              <ReactTable
                getTrProps={this.handleTablePropsChanged}
                className="-striped -highlight"
                data={this.props.groups}
                columns={columns}
                sortable={false}
                minRows={1}
                defaultPageSize={5}
                pageSizeOptions={[5, 10, 15]}
              />
            </div>
          </Accordion>
        }
      </>
    );
  }
}
