import * as React from "react";
import {DualListBox} from "../../common/dual-listbox";
import {ICustomerGroup} from "src/api/customerGroups/models";
import {IRootReducer} from "../../store/rootReducer";
import {connect} from "react-redux";
import {IDropDownItem} from "../../common";
import {IListBoxItem} from "../../common/dual-listbox/IListBoxItem";
import Accordion from "../../common/Accordion";
import BeCodeAssignerItem from "./BeCodeAssignerItem";
import {bindActionCreators, Dispatch} from "redux";
import * as configurationActions from "../store/CustomerGroup/Actions";

interface IBeCodeAssignerOwnProps {
    editPermissions: string;
}

interface IBeCodeAssignerProps extends  IBeCodeAssignerOwnProps {
    actions: typeof configurationActions;
    customerGroup: ICustomerGroup;
    beCodes: IDropDownItem[];
    hasEditPermission: boolean;
}

interface IBeCodeAssignerState {
    customerGroup: ICustomerGroup;
    beCodes: IListBoxItem[];
    selectedBeCodes: IListBoxItem[];
}

class BeCodeAssigner extends React.Component<IBeCodeAssignerProps, IBeCodeAssignerState> {
    public constructor(props: IBeCodeAssignerProps) {
        super(props);
        this.onActivate = this.onActivate.bind(this);
        this.onApply = this.onApply.bind(this);
        this.state = {
            customerGroup: props.customerGroup,
            beCodes: [],
            selectedBeCodes: []
        }
    }

    public componentDidUpdate(prevProps: Readonly<IBeCodeAssignerProps>) {
        if (this.state.customerGroup.id !== this.props.customerGroup.id ||
            this.state.beCodes.length !== this.props.beCodes.length) {

            const mappedBeCodes = this.props.beCodes.map(item => ({key: item.key, label: item.text}));
            this.setState({
                customerGroup: this.props.customerGroup,
                beCodes: mappedBeCodes,
                selectedBeCodes: mappedBeCodes.filter(code => this.props.customerGroup.beCodes.indexOf(code.key) !== -1)
            });
        }
    }

    public render() {
        if (this.state.customerGroup.id === '') {
            return <div/>
        }
        return (
            <div style={{marginBottom: "2rem"}}>
                <Accordion title={"BE Codes Assigner"} isExpanded={true}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <DualListBox
                            options={this.state.beCodes}
                            selected={this.state.selectedBeCodes}
                            readonly={!this.props.hasEditPermission}
                            onActivate={this.onActivate}
                            onApply={this.onApply}
                            sort={true}
                            height={240}
                            leftTitle={"Unassigned BE Codes"}
                            rightTitle={"Assigned BE Codes"}
                            rightUsesTemplate={true}
                        >
                            <BeCodeAssignerItem readonly={!this.props.hasEditPermission} />
                        </DualListBox>
                    </div>
                </Accordion>
            </div>
        );
    }

    private onActivate(selected: IListBoxItem[], prevSelected: IListBoxItem[]) {
        this.props.actions.appendCustomerGroupDifferences(this.props.customerGroup.name, selected.map(item => item.label));
    }

    private onApply(selected: IListBoxItem[], deselected: IListBoxItem[]) {
        this.props.actions
            .synchronizeCustomerAssignment(
                this.props.customerGroup.name, selected.map(c => c.label), deselected.map(c => c.label)
            );
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    const props: Partial<IBeCodeAssignerProps> = {
        actions: bindActionCreators(configurationActions, dispatch)
    }
    return props;
}
const mapStateToProps = (state: IRootReducer, ownProps: IBeCodeAssignerOwnProps) => {
    const props: Partial<IBeCodeAssignerProps> = {
        beCodes: state.groupConfigurationView.customers,
        customerGroup: state.groupConfigurationView.selectedCustomerGroup,
        hasEditPermission: state.app.userPermissions.businessFunctions.indexOf(ownProps.editPermissions) !== -1
    }
    return props;
}

export default connect(mapStateToProps, mapDispatchToProps)(BeCodeAssigner);