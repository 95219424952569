interface IApiConfig {
    apiUrl: string;
}
interface IBrainConsumer {
    ["brain-consumer-secure-gateway-secret"]: string;
}

export class GlobalConfig {

    public static getApiUrl(): string {
        return this.apiConfig.apiUrl;
    }

    public static setApiConfig(apiConfig: any) {
        this.apiConfig = apiConfig;
    }

    public static getBrainConsumer(): string {
        return this.brainConsumer["brain-consumer-secure-gateway-secret"];
    }

    public static setBrainConsumer(brainConsumer: any) {
        this.brainConsumer = brainConsumer;
    }

    private static apiConfig: IApiConfig = {
        apiUrl: ''
    }

    private static brainConsumer: IBrainConsumer = {
        ["brain-consumer-secure-gateway-secret"]: ''
    }
}
