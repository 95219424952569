import * as React from "react";
import ReactTable from "react-table";
import {ColumnFactory} from "../../common/data-table/ColumnFactory";
import Accordion from "../../common/Accordion";
import { IProcessChangeHistory } from "src/api/processes/models";

interface  IProcessChangeHistoryProps {
    changeHistory: IProcessChangeHistory[];
}
export class ProcessChangeHistory extends React.Component<IProcessChangeHistoryProps> {
    public constructor(props: IProcessChangeHistoryProps) {
        super(props);
    }

    public render() {
        return (
            <Accordion title={"Change History (" + this.props.changeHistory.length + ")"} key={"Change History"} isExpanded={false}>
                <div className="regular-inactive-text">(New changes will be displayed after about 5-10 mins.)</div>
                <br />
                <div className="data-table-container modal-data-table">
                    <ReactTable
                        data={this.props.changeHistory}
                        columns={[
                            ColumnFactory.dateColumn("dateTime", "Timestamp", { format: "DD-MM-YYYY HH:mm", width: 120 }),
                            ColumnFactory.standardColumn("userName", "User", { width: 250 }),
                            ColumnFactory.standardColumn("comments", "Comments")
                        ]}
                        minRows={1}
                        pageSize={10}
                        showPagination={true}
                        sortable={false}
                        resizable={true}
                        className="data-table"
                    />
                </div>
            </Accordion>
        )
    }
}