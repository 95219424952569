import * as actionTypes from './actions/rejectedMessagesActionTypes';
import * as moment from "moment";
import {IMonitorContainer, IMonitorListEntry} from "../common/monitor/model/monitor";

export const rejectedMessagesContainerReducer = (
    state: IMonitorContainer = {
        monitorMoreFilters: true,
        filter: {
            CorrelationId: '',
            System: '',
            UniqueId: '',
            RejectionType: '',
            NextPageToken: '',
            DateFrom: moment().startOf('day'),
            DateTo: moment().startOf('hour').add(1, 'hour')
        }
    } as IMonitorContainer,
    action: any
): IMonitorContainer => {

    switch(action.type) {
        case actionTypes.MONITOR_LOAD_IN_PROGRESS:
            return { ...state, isLoading: true };

        case actionTypes.MONITOR_LOAD_ERROR:
            return { ...state,  errorLoading: true };

        case actionTypes.MONITOR_LOAD_REJECTED_MESSAGES_SUCCESS:
            return { ...state, isLoading: false, monitorMessages: action.result.monitorMessages, nextPageToken: action.result.nextPageToken };

        case actionTypes.MONITOR_LOAD_REJECTED_MESSAGES_NEXT_PAGE_SUCCESS:
            const monitorMessages: IMonitorListEntry[] = state.monitorMessages.concat(action.result.monitorMessages);
            return { ...state, isLoading: false, monitorMessages, nextPageToken: action.result.nextPageToken };

        case actionTypes.MONITOR_LOAD_FILTER_DICTIONARIES:
            return { ...state, isLoading: false, filter: {...state.filter, ...action.filter }};

        case actionTypes.MONITOR_CLEAR_RESULTS:
            return { ...state, monitorMessages: [], nextPageToken: ''};

        case actionTypes.MONITOR_CHANGE_PROCESSES_MORE_FILTERS:
            return { ...state, monitorMoreFilters: !action.currentMoreFilters };

        case actionTypes.MONITOR_CHANGE_FILTERS:
            return {...state, filter: {...state.filter, ...action.newFilters}};
    }

    return { ...state };
}