
import * as actionTypes from './rejectedMessagesActionTypes';
import {MonitorApiService} from "../api/monitorApiService";
import {IOperationalFilter} from "../../operational/OperationalApi";
import {IMonitorFilter, IMonitorFilterState} from "../../common/monitor/model/filtering";
import {IMonitorMessagesApiResult} from "../model/RejectedMessagesModel";


export const loadError = () => {
    return { type: actionTypes.MONITOR_LOAD_ERROR, result: { errorLoading: true } };
}

export const loadInProgress = () => {
    return { type: actionTypes.MONITOR_LOAD_IN_PROGRESS };
}

export const loadRejectedMessagesSuccess = (result: IMonitorMessagesApiResult) => {
    return { type: actionTypes.MONITOR_LOAD_REJECTED_MESSAGES_SUCCESS, result };
}

export const loadRejectedMessagesNextPageSuccess = (result: IMonitorMessagesApiResult) => {
    return { type: actionTypes.MONITOR_LOAD_REJECTED_MESSAGES_NEXT_PAGE_SUCCESS, result };
}

export const loadFilterDictionariesSuccess = (filter: IMonitorFilterState) => {
    return { type: actionTypes.MONITOR_LOAD_FILTER_DICTIONARIES, filter };
}

export const clearResults = () => {
    return { type: actionTypes.MONITOR_CLEAR_RESULTS };
}

export const changeFilterToProcesses = () => {
    return { type: actionTypes.MONITOR_CHANGE_FILTER_TO_PROCESSES };
}

export const changeMoreFiltersOnProcesses = (currentMoreFilters: boolean) => {
    return { type: actionTypes.MONITOR_CHANGE_PROCESSES_MORE_FILTERS, currentMoreFilters };
}

export const changeFilterState = (newFilters: IMonitorFilterState) => {
    return { type: actionTypes.MONITOR_CHANGE_FILTERS, newFilters};
}

export const loadRejectedMessages = (filters: IMonitorFilter) => {
    return (dispatch: any) => {
        dispatch(loadInProgress());
        const api = new MonitorApiService();
        api.getRejectedMessages(filters)
            .then((result) => {
                if (filters.NextPageToken && filters.NextPageToken.length > 0) {
                    dispatch(loadRejectedMessagesNextPageSuccess(result));
                }
                else {
                    dispatch(loadRejectedMessagesSuccess(result));
                }
            })
            .catch(error => {
                dispatch(loadError());
            });
    };
}

export const loadFilterDictionaries = (filter: IOperationalFilter) => {
    return (dispatch: any) => {
        dispatch(loadInProgress());
        const api = new MonitorApiService();
        api.getFilterDictionaries()
            .then((result) => {
                dispatch(loadFilterDictionariesSuccess(result));
            })
            .catch(error => {
                dispatch(loadError());
            });
    };
}
