import { confirmAlert } from "react-confirm-alert";
import "./confirmAlertMessage.css";

export const confirmMessageAlert = (
  title: string,
  message: string,
  confirmCallback: (event: any) => void,
  confirmText = "Confirm",
  cancelText = "Cancel"
) => {
  confirmAlert({
    title,
    message,
    buttons: [
      {
        label: confirmText,
        onClick: () => {
          confirmCallback(event);
        },
      },
      {
        label: cancelText,
        onClick: () => {
          return null;
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: false,
  });
};
