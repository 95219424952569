import * as React from "react";
import Tree from "react-d3-tree";
import { IStepDefinition } from "src/api/processes/models";
import { ICustomerProcessDefinition } from "../models/ICustomerProcessDefinition";
import { ITreeObject } from "../models/ITreeObject";
import {StepTreeNodeLabel} from "./StepTreeNodeLabel";

const foreignObjectWrapper = {
    y: -115,
    x: -140,
    width: 280,
    height: 90
}

const translation = {
    x: 350,
    y: 100
}

const separation = {
    siblings: 2,
    nonSiblings: 2
}

const nodeSvgShape = {
    shape: "rect",
    shapeProps: {
        width: "40",
        height: "40",
        x: "-20",
        y: "-20"
    }
}

export interface IStepElement {
    messageType:string;
    name: string;
}


interface IStepsTreeProps {
    processDefinition: ICustomerProcessDefinition | null;
    onTreeNodeClick: (elements: IStepElement[], state: boolean) => void
    readonly: boolean;
}


export default class StepsTree extends React.Component<IStepsTreeProps> {
    private static getTreeObject(step: IStepDefinition): ITreeObject {
        return {
            enabled: step.enabled,
            name: step.name,
            messageType: step.messageType,
            childSteps: step.childSteps,
            attributes: {
                messageType: step.messageType
            },
            children: [],
            nodeSvgShape: {
                shape: "rect",
                shapeProps: {
                    ...nodeSvgShape.shapeProps,
                    fill: step.enabled ? "#2DBA5E" : "#D62D23"
                }
            }
        }
    }

    private treeRoot: ITreeObject[];

    public constructor(props: IStepsTreeProps) {
        super(props);
        this.onTreeNodeClick = this.onTreeNodeClick.bind(this);
        this.treeRoot = [];
    }

    public render() {
        this.buildTree();
        if (this.isNotEmpty()) {
            return (
                <div>
                    {this.renderLabel()}
                    {this.renderTree()}
                </div>
            );
        }
        return <div>Tree data not possible to render</div>;

    }

    private renderLabel(): JSX.Element {
        if (this.props.readonly) {
            return <></>
        }
        return (
            <>
                <br/>
                <span className="regular-inactive-text">Click on step icon to toggle step activity</span>
            </>
        )
    }

    private renderTree(): JSX.Element {
        return (
            <div id="treeWrapper" style={{width: "100%", height: "600px", border: "solid"}}>
                <Tree
                    data={this.treeRoot}
                    onClick={this.onTreeNodeClick}
                    allowForeignObjects={true}    // const tree config
                    nodeLabelComponent={{         // const tree config
                        render: <StepTreeNodeLabel/>,    // const tree config
                        foreignObjectWrapper      // const tree config
                    }}
                    orientation={"vertical"}      // const tree config
                    pathFunc={"straight"}         // const tree config
                    translate={translation}       // const tree config
                    separation={separation}       // const tree config
                    collapsible={false}           // const tree config
                    nodeSvgShape={nodeSvgShape}   // const tree config
                    transitionDuration={0}        // const tree config
                />
            </div>
        )
    }

    private isNotEmpty(): boolean {
        return !!this.props.processDefinition && this.treeRoot !== null && this.treeRoot.length > 0;
    }

    private fetchElementsToChange(elements: any, node: any, state: boolean) {
        if (node.enabled === state) {
            elements.push(node)
        }
        // drill down to disable children nodes
        if (state && node.children !== undefined) {
            node.children.forEach((childNode: any) => {
                this.fetchElementsToChange(elements, childNode, state)
            })
        }
        // go up to enable parent node
        if (!state && node.parent !== undefined) {
            this.fetchElementsToChange(elements, node.parent, state)
        }
    }

    private onTreeNodeClick(node: any): void {
        if (this.props.readonly) {
            return
        }
        const elements: IStepElement[] = [];
        this.fetchElementsToChange(elements, node, node.enabled);
        const mappedElements = elements.map(el => (
            {
                messageType: el.messageType,
                name: el.name
            }
        ));

        this.props.onTreeNodeClick(mappedElements, !node.enabled);
    }

    private findProcessesInTree(rootStep: ITreeObject, treeProcessDefinition: ITreeObject[]): ITreeObject[] {
        const processes: ITreeObject[] = []
        for (const childStep of rootStep.childSteps) {
            const item = treeProcessDefinition.find(tpd => tpd.messageType === childStep.step)
            if (item !== undefined) {
                processes.push(item)
            }
        }
        return processes;
    }

    private buildTree() {
        if (this.props.processDefinition == null || !this.props.processDefinition!.steps) {
            return;
        }
        const treeProcessDefinition: ITreeObject[] = this
            .props.processDefinition!.steps.map(step => StepsTree.getTreeObject(step));

        for (const mainStep of treeProcessDefinition) {
            const processes = this.findProcessesInTree(mainStep, treeProcessDefinition);

            if (processes.length > 0) {
                for (const process of processes) {
                    const childToAdd = mainStep.children.filter(treeElement => treeElement.name === process.name)
                    if (childToAdd.length === 0) {
                        mainStep.children.push(process)
                    }
                }
            }
        }
        const root = treeProcessDefinition[0];
        this.treeRoot = [ root ]
    }
}
