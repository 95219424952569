import { ApiServiceBase } from "../common/ApiServiceBase";
import { IUserPermissions } from "./main";

const GET_USERPERMISSIONS_URL = '/users/me/permissions';

export class MainApiService extends ApiServiceBase {

    public getProcessesInstances(): Promise<IUserPermissions> {
        return this.get(GET_USERPERMISSIONS_URL)
            .then(response => {
                return new Promise<IUserPermissions>(resolve => resolve(response));
            });
    }
}