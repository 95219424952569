import * as React from 'react';
import { withRouter } from 'react-router-dom'
import "./styles/ApplicationError.css";
import authentication from '../common/Authentication';
import { AdvancedError } from './AdvancedError';
import { UNKNOWN_ERROR } from './CustomErrorTypes';
import {Component} from "react";

class ErrorContainer extends Component<{location:any}> {
    private error: AdvancedError;
    constructor(props: any) {        
        super(props);
        if(authentication.getOccuredError()) {
             authentication.clearErrors();
        }
        this.error = this.props.location.state ? 
        this.props.location.state : new AdvancedError(UNKNOWN_ERROR.message, UNKNOWN_ERROR.errorCode, UNKNOWN_ERROR.name, UNKNOWN_ERROR.description);
    }

    public render() {
        return (
            <div className="application-error">
                <div className="message">
                    <h1 className="name">{this.error.name}</h1>
                    <h2 className="content">{this.error.friendlyMessage}</h2>
                </div>
                {this.error.details &&
                <div className="stack">
                    <h2 className="error-code">{this.error.errorCode ? "Error Code: "+this.error.errorCode : ""}</h2>
                    <h3>Error details:</h3>
                    <span className="details">{this.error.details}</span>
                </div>
                }
            </div>
        );
    }
}

export default withRouter(ErrorContainer);