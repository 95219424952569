import * as React from "react"
import "./styles/ResultItem.css"

interface IResultItemProps {
	title?: string
	value?: string
	className?: string
}

export const ResultItem = (props: IResultItemProps) => {
	const classNameForContainer = props.className + " small-body-text"

	return (
		<div className={classNameForContainer}>
			<span className="small-inactive-text result-item-title">{props.title}: </span>
			{props.value}
		</div>
	)
}
